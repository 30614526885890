<template>
  <div class="question-detail-box">
    <div class="detail-info">
      <div class="detail-title">{{detailData.question}}</div>
      <div class="detail-msg">{{detailData.answer}}</div>
      <div class="detail-footer">
        <span>还是不能解决？</span>
        <a :href="'tel:'+kefu">联系客服</a>
      </div>
    </div>
    <div class="relation-question" v-if="relevant.length>0">
      <div class="title">相关问题</div>
      <div class="problems">
        <div class="item" v-for="item in relevant" :key="item.id" @click="openPage({url: '/questionDetail?id='+item.id, hreftype: 'redirectTo'})">{{item.question}}</div>
      </div>
    </div>
  </div>
</template>

<script>
var util = require("@/utils/util");
import { Indicator } from "mint-ui";
export default {
  data() {
    return {
      detailData: {},
      relevant: [],//相关问题
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id){
      util.showToast("常见问题id丢失");
      util.openPage({url: -1, hreftype:'navigateBack'})
      return false;
    }
    this.getDetail(id);
  },
  watch:{
    $route() {
      const { id } = this.$route.query;
      if(id){
        this.data = {};
        window.scrollTo(0,0);
        this.getDetail(id);
      }
    },
  },
  methods: {
    getDetail(id) {
      const ts = this;
      Indicator.open("加载中...");
      util.requests("post", {
        url: "question/getQuestionDetail",
        data: {id: id}
      }).then((res) => {
        Indicator.close();
        if (res.code && res.data.detail) {
          ts.setDataArr({
            detailData: res.data.detail,
            relevant: res.data.relevant
          })
        }else{
          util.showToast("获取详情异常");
          util.openPage({url: -1, hreftype:'navigateBack'})
          return false;
        }
      });
    },
    openPage: util.openPage
  }
};
</script>

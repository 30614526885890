<template>
  <div :class="['location-view']" @click="getGeolocation">
    <div :class="['location-icon', isLoading ? 'loading' : '']"></div>
  </div>
</template>

<script>
import AMap from "AMap";
import { Toast } from "mint-ui";
export default {
  name: "Location",
  props: ["bottomMargin"],
  data() {
    return {
      map: this.$parent.map,
      userImg: this.$parent.userImg,
      currentMarker: null,
      currentPosition: [],
      geolocation: null,
      isLoading: false,
      locationLoding: false,
      isFail: false,
    };
  },
  mounted() {
    let that = this;
    this.setLocation();
    // 陀螺仪
    var ua = navigator.userAgent.toLowerCase();
    if (window.DeviceOrientationEvent) {
      if (/android/.test(ua)) {
        window.addEventListener(
          "deviceorientationabsolute",
          DeviceOrientationHandler,
          false
        );
        function DeviceOrientationHandler(event) {
          if (that.currentMarker) {
            document.querySelector(
              ".amap-geolocation-bg"
            ).style.transform = `rotate(${Math.round(360 - event.alpha)}deg)`;
          }
        }
      } else {
        window.addEventListener(
          "deviceorientation",
          DeviceOrientationHandler,
          false
        );
        function DeviceOrientationHandler(event) {
          if (that.currentMarker) {
            if (document.querySelector(".amap-geolocation-bg")) {
              document.querySelector(
                ".amap-geolocation-bg"
              ).style.transform = `rotate(${event.webkitCompassHeading}deg)`;
            }
          }
        }
      }
    }
  },
  methods: {
    // 定位功能
    setLocation() {
      var that = this;
      var geolocation = new qq.maps.Geolocation();
      that.geolocation = geolocation;
      that.isLoading = true;
      geolocation.getLocation(that.onComplete, that.onError);
      geolocation.watchPosition(that.onComplete);
    },
    //解析定位结果
    onComplete(data) {
      var that = this;
      that.currentPosition = [data.lng, data.lat];
      that.$parent.currentPosition = [data.lng, data.lat];
      that.setCurrentMarker([data.lng, data.lat]);
      that.isLoading = false;
      if (that.locationLoding) {
        that.map.panTo(that.currentPosition);
        that.map.setZoom(17);
        that.locationLoding = false;
      }
    },
    //解析定位错误信息
    onError(data) {
      if (!this.isFail) {
        Toast({
          message: "定位失败，请打开GPS定位或到空旷的地方再尝试",
          duration: 3000,
        });
        this.isLoading = false;
        this.locationLoding = false;
        this.isFail = true;
      }
    },
    // 获取定位事件
    getGeolocation() {
      var that = this;
      that.isLoading = true;
      that.locationLoding = true;
      that.isFail = false;
      that.geolocation.getLocation(that.onComplete, that.onError);
    },
    // 设置当前定位的marker
    setCurrentMarker(position) {
      var that = this;
      if (that.currentMarker) {
        that.currentMarker.setPosition(position);
      } else {
        const _src = require('@/assets/lubao_head.png')
        that.currentMarker = new AMap.Marker({
          map: that.map,
          position: position, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          offset: new AMap.Pixel(-32, -32),
          zIndex: 105,
          content: `<div class="amap-geolocation-box">
          <img class="user-head" src="${_src}">
          <div class="amap-geolocation-bg"></div>
          </div>`,
        });
      }
    },
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Cart from "../views/Cart.vue";
import changeUrl from "../views/changeUrl.vue";
import Kefu from "../views/Kefu.vue";
import Question from "../views/Kefu-question.vue";
import QuestionDetail from "../views/Kefu-question-detail.vue";
import List from "../views/List.vue";
import Complaint from "@/views/Complaint.vue";
import NoticeDetail from "@/views/NoticeDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "智慧导览",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/href",
    name: "changeUrl",
    component: changeUrl,
  },
  {
    path: "/kefu",
    name: "Kefu",
    component: Kefu,
    meta: {
      title: "客服",
    },
  },
  {
    path: "/question",
    name: "question",
    component: Question,
    meta: {
      title: "常见问题",
    },
  },
  {
    path: "/questionDetail",
    name: "question-detail",
    component: QuestionDetail,
    meta: {
      title: "常见问题",
    },
  },
  {
    path: "/list",
    name: "list",
    component: List,
  },
  {
    path: "/complaint",
    name: "complaint",
    component: Complaint,
    meta: {
      title: "在线投诉",
    },
  },
  {
    path: "/noticeDetail",
    name: "noticeDetail",
    component: NoticeDetail,
    meta: {
      title: "通知详情",
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <div class="question-box">
    <main>
      <div class="question-item" v-for="item in listData" :key="item.id" @click="openPage({url: '/questionDetail?id='+item.id})">{{item.question}}</div>
    </main>
    <footer>
      <a class="phone-btn" :href="'tel:'+kefu">
        <i class="phone"></i>
        客服电话：{{kefu}}
      </a>
    </footer>
  </div>
</template>

<script>
var util = require("@/utils/util");
import { Indicator } from "mint-ui";
export default {
  data() {
    return {
      dataLoading: false,
      listData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const ts = this;
      Indicator.open("加载中...");
      util.requests("post", {
        url: "question/getQuestionList",
      }).then((res) => {
        Indicator.close();
        if (res.code) {
          ts.setDataArr({
            listData: res.data
          })
        }
      });
    },
    openPage: util.openPage
  }
};
</script>

<template>
  <div class="map-set-view">
    <div class="bg" @click="parent.mapSetViewShow = false"></div>
    <div class="main">
      <div class="title">设置</div>
      <mt-cell title="手绘地图" class="cell-border-bottom">
        <mt-switch
          v-model="parent.setShowMap"
          @change="setMapEvent"
        ></mt-switch>
      </mt-cell>
      <!-- <mt-cell title="自动播放讲解" class="cell-border-bottom">
                <mt-switch v-model="parent.setAutoPlay" @change="setAutoPlayEvent"></mt-switch>
            </mt-cell>
            <mt-cell title="英文语音播放" class="cell-border-bottom" v-if="parent.scenicInfo.voice_en_enable">
                <mt-switch v-model="parent.setAutoLanguage" @change="setLanguageEvent"></mt-switch>
            </mt-cell> -->
      <!-- <div class="mt-cell-toaks" style="margin-top:12px" @click="toAsk">
              <img src="https://lubao2.16u.cc/static/images/feedback_icon.png" width="24" height="24">
              用户反馈与投诉
            </div> -->
    </div>
  </div>
</template>

<script>
var util = require("@/utils/util");
import { Switch } from "mint-ui";
import { Cell } from "mint-ui";
import { Radio } from "mint-ui";
export default {
  name: "MapSet",
  props: {
    toAsk: Function,
  },
  data() {
    return {
      parent: this.$parent,
      setSpeedShow: false,
    };
  },
  methods: {
    setMapEvent() {
      var that = this;
      if (that.parent.setShowMap) {
        that.parent.imageLayer.show();
      } else {
        that.parent.imageLayer.hide();
      }
    },
    setAutoPlayEvent() {},
    setLanguageEvent() {},
  },
};
</script>

<template>
  <div class="notice-container">
    <div class="common-detail">
      <div class="common-detail-title">{{ data.notice_title }}</div>
      <div class="common-detail-time flex ac jb">
        <div>{{ data.create_time }}</div>
        <!-- <div class="flex ac">
          <div class="notice-icon" />
          <div class="m-r-8 m-l-8">{{ data.view_count }}</div>
        </div> -->
      </div>
      <div class="custom-parse" v-html="data.content" />
    </div>
  </div>
</template>

<script>
var util = require("@/utils/util");
export default {
  data() {
    return {
      data: {},
      first: false,
    };
  },
  activated() {
    const { id } = this.$route.query;
    this.data = {};
    if (id) {
      this.getData({
        id,
      });
    }
  },
  methods: {
    getData(params) {
      util
        .requests("post", {
          url: "notice/getInfo",
          data: params,
        })
        .then((res) => {
          this.data = res.data || {};
        });
    },
  },
};
</script>

<style scoped>
.notice-container {
  padding: 30px;
}
.custom-parse {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.45);
}

.common-detail-title {
  font-size: 40px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

.notice-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url("~@/assets/icon-view.png") center no-repeat;
  background-size: contain;
}

.common-detail-time {
  margin: 10px 0 20px;
  color: #8c8c8c;
  font-size: 26px;
}
</style>

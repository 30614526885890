<template>
  <div></div>
</template>
<script>
export default {
  name: "AutoPlay",
  data() {
    return {
      isInView: false,
      play_area: this.$parent.play_area,
      bounds: [],
      prevMarkerIndex: null,
    };
  },
  mounted() {
    var that = this;
    var TL, TR, BL, BR; //地图四角经纬度;
    TR = that.$parent.bounds.NorthEast;
    TL = [that.$parent.bounds.NorthEast[0], that.$parent.bounds.SouthWest[1]];
    BR = [that.$parent.bounds.SouthWest[0], that.$parent.bounds.NorthEast[1]];
    BL = that.$parent.bounds.SouthWest;
    that.bounds = [TL, TR, BR, BL];
    // 是否在景区内
    var interval = setInterval(function() {
      if (that.$parent.setAutoPlay) {
        that.autoPlay();
      } else {
        clearInterval(interval);
      }
    }, 5000);
  },
  methods: {
    // 排序
    compareUp(dis1, dis2) {
      return dis1.dis - dis2.dis;
    },
    // 自动播放功能
    autoPlay() {
      var that = this;
      var currentMarkers = that.$parent.currentMarkers;
      var currentPosition = that.$parent.currentPosition;
      //   var currentPosition = [118.066846,24.444604];
      var currentAudio = that.$parent.currentAudio;
      var isLine = that.$parent.lineDetailShow;
      //判定是否在景区内
      that.isInView = AMap.GeometryUtil.isPointInRing(
        currentPosition,
        that.bounds
      );
      var disArr = [];
      if (that.isInView) {
        if (!currentAudio || (currentAudio && currentAudio.paused)) {
          currentMarkers.forEach((item, index) => {
            var dis = AMap.GeometryUtil.distance(
              currentPosition,
              item.getPosition()
            );
            var temp = { index: index, dis: dis };
            disArr.push(temp);
          });
          var minDisArr = disArr.sort(that.compareUp);
          var mkDis = minDisArr[0].dis;
          var mkIndex = minDisArr[0].index;
          if (mkDis <= currentMarkers[mkIndex].getExtData().autoPlayAear) {
            if (that.prevMarkerIndex != mkIndex) {
              that.prevMarkerIndex = mkIndex;
              that.$parent.map.setFitView(currentMarkers[mkIndex], false);
              that.$parent.markerEvent(currentMarkers[mkIndex], isLine);
              setTimeout(() => {
                that.$parent.autoPlayToogle();
                document.addEventListener("touchstart", that.play);
              }, 300);
            }
          }
        }
      }
    },
    play() {
      var that = this;
      that.$parent.currentAudio.play();
      document.removeEventListener("touchstart", that.play);
    },
  },
};
</script>

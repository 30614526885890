<template>
  <div class="home">
    <!-- 顶部导航 -->
    <div :class="['top-nav flex ac', lineDetailShow ? 'hide' : '']">
      <div class="serch-btn" @click="openPages(curMap_id)"></div>
      <div class="select-marker flex">
        <div
          :class="['marker-item', index == currentMarkersIndex ? 'active' : '']"
          v-for="(item, index) in mapData"
          :key="index"
          @click="setMarkers(item.markers, item.icon, item.defaultImg, index)"
        >
          {{ item.type }}
        </div>
      </div>
      <div class="more-btn-box flex jc">
        <div class="more-btn" @click="moreModelToogle"></div>
      </div>
    </div>
    <!-- 顶部渐变 -->
    <div :class="['nav-linear', lineDetailShow ? 'hide' : '']"></div>
    <!-- 导航展开 -->
    <div class="top-nav-more" v-if="navMoreShow">
      <div class="bg" @click="moreModelToogle"></div>
      <div class="main">
        <div class="title">
          全部
          <span class="nav-more-close" @click="moreModelToogle"></span>
        </div>
        <div class="markers-box">
          <span
            :class="[
              'marker-item',
              index == currentMarkersIndex ? 'active' : '',
            ]"
            @click="setMarkers(item.markers, item.icon, item.defaultImg, index)"
            v-for="(item, index) in mapData"
            :key="index"
            >{{ item.type }}</span
          >
        </div>
      </div>
    </div>

    <!-- 地图容器 -->
    <div class="amap-map" id="map"></div>

    <!-- 景区初始化介绍购买激活弹窗 -->
    <scenic-model
      v-if="sceniceModelShow"
      :scenicInfo="scenicInfo"
    ></scenic-model>

    <!-- 公告 -->
    <div
      :class="['notice-box', detailViewShow || lineDetailShow ? 'hide' : '']"
      v-if="noticeData.length > 0"
    >
      <notice :noticeData="noticeData" :toNotice="toNotice"></notice>
    </div>
    <!-- 天气 -->
    <weather
      v-if="false"
      :class="[
        detailViewShow || lineDetailShow ? 'hide' : '',
        noticeData.length <= 0 ? 'top-one' : 'top-two',
      ]"
    ></weather>

    <!-- 返回主景区 -->
    <div
      @click="backMain"
      v-if="sid != curSid && map_id != curMap_id"
      :class="[
        'back-main',
        detailViewShow || lineDetailShow ? 'hide' : '',
        noticeData.length <= 0 ? 'top-one' : 'top-two',
      ]"
    >
      县域地图
    </div>

    <!-- 定位 -->
    <location
      v-if="isLBS && map && mapInitEnd"
      :bottomMargin="bottomMargin"
    ></location>
    <!-- 返回景区 -->
    <div
      :class="['return-scenic', bottomMargin ? 'iphonex-mb' : '']"
      v-if="returnViewShow"
      @click="returnCenter"
    >
      <i class="icon"></i>
      返回景区
    </div>

    <!-- 底部nav -->
    <div
      :class="['bottom-nav', detailViewShow || lineDetailShow ? 'hide' : '']"
    >
      <div class="nav-item" @click="toList">
        <div class="icon list"></div>
        <p class="title">列表</p>
      </div>
      <div class="nav-item" @click="openPage({ url: '/kefu' })" v-if="scenicInfo.kefu_switch">
        <div class="icon customer"></div>
        <p class="title">客服</p>
      </div>
      <div class="nav-item" @click="mapSetViewShow = true">
        <div class="icon setting"></div>
        <p class="title">设置</p>
      </div>
      <div class="nav-item" v-if="isYzsxh" @click="jumpOtherMiniPro()">
        <div class="icon ar"></div>
        <p class="title">AR</p>
      </div>
    </div>
    <!-- 游览路线 -->
    <div
      :class="['recommend', detailViewShow || lineDetailShow ? 'hide' : '']"
      v-if="scenicInfo.route_switch"
      @click="
        lineSelect();
        currentMarkersIndex = 0;
      "
    >
      <img
        class="recommend-image"
        :src="require('@/assets/recommend-line.png')"
      />
    </div>
    <!-- 景区详情 -->
    <detail-view
      ref="detailview"
      v-if="detailViewShow"
      :isLineMarker="isLineMarker"
      :isAuth="is_auth"
      :markerData="prevTargetMarker.getExtData()"
    ></detail-view>

    <!-- 选择路线 -->
    <div class="line-select-view" v-if="lineSelectShow">
      <div class="bg" @click="lineSelectShow = false"></div>
      <div class="main">
        <div
          v-for="(item, index) in lineData"
          :key="index"
          :class="[
            'line-item',
            bottomMargin && index == lineData.length - 1
              ? 'iphonex-pd-bottom'
              : '',
          ]"
          @click="showLineDetail(index)"
        >
          <div class="title-box flex jb ac">
            <div class="title">{{ `${index + 1}号线路` }}</div>
            <div class="title-icon" />
          </div>
          <div class="info flex jb ac">
            <div class="flex ac">
              <div class="info-num">{{ item.markers.length }}个景点</div>
              <div class="info-icon" />
              <div class="flex ac">
                <div class="info-distance">
                  {{
                    item.distance < 1000
                      ? item.distance
                      : (item.distance / 1000).toFixed(2)
                  }}
                </div>
                <div class="info-company">
                  {{ item.distance < 1000 ? "m" : "Km" }}
                </div>
              </div>
            </div>
            <div class="info-btn">
              查看详情
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 线路详情 -->
    <line-view
      v-if="lineDetailShow"
      :lineData="lineData"
      :index="selectLineIndex"
    ></line-view>

    <!-- 地图设置 -->
    <map-set v-if="mapSetViewShow" :toAsk="toAsk"></map-set>
    <!-- 修改音频 -->
    <LanuageSet v-if="langepop" />
    <!-- <map-set v-if="mapSetViewShow" :toAsk="toAsk"></map-set> -->
    <!-- 自动播放 -->
    <auto-play v-if="setAutoPlay"></auto-play>

    <!-- 介绍模块 -->
    <presentation v-if="guideFlag == 0" :guideFlag="guideFlag"></presentation>

    <!-- 底部渐变 -->
    <div
      :class="['footer-linear', detailViewShow || lineDetailShow ? 'hide' : '']"
    ></div>
  </div>
</template>

<script>
var util = require("@/utils/util");
var auth = require("@/utils/auth");
import ScenicModel from "@/components/ScenicModel.vue";
import Notice from "@/components/Notice.vue";
import Weather from "@/components/Weather.vue";
import Coupon from "@/components/Coupon.vue";
import Ticketwindow from "@/components/Ticketwindow.vue";
import Location from "@/components/Location.vue";
import DetailView from "@/components/DetailView.vue";
import LineView from "@/components/LineView.vue";
import MapSet from "@/components/MapSet.vue";
import LanuageSet from "@/components/SetLanuage.vue";
import AutoPlay from "@/components/AutoPlay.vue";
import Presentation from "@/components/Presentation.vue";
import AMap from "AMap";
import { Range, Toast, Indicator } from "mint-ui";
// 测试JSON数据

export default {
  name: "home",
  components: {
    ScenicModel,
    Notice,
    Weather,
    Coupon,
    Ticketwindow,
    Location,
    DetailView,
    LineView,
    MapSet,
    AutoPlay,
    Indicator,
    Presentation,
    LanuageSet,
    Range,
  },
  data() {
    return {
      scenicInfo: [],
      sceniceModelShow: false, //景区介绍激活弹框
      bottomMargin: false, //iphoneX底部兼容性
      sid: "",
      curSid: "", //记录当前的景区ID
      curMap_id: "", //记录当前的地图ID
      map_id: "",
      navMoreShow: false, //顶部查看更多数据点模态框
      isFirst: true, //是否初始化地图
      map: null, //地图对象
      mapInitEnd: false,
      mapUrl: "", //手绘地图地址
      map_slice: null, //是否切片手绘地图模式
      map_slice_url: "", //切片地图目录
      slice_root_src: "https://lubaomap.oss-cn-hangzhou.aliyuncs.com", //切片库根目录地址
      is_auth: true, //地图是否激活,默认不弹窗
      mapCenter: null,
      maxZoom: 18,
      guideFlag: 1,
      bounds: {
        //地图范围区域
        SouthWest: [], //西南角（左下）
        NorthEast: [], //东北角（右上）
      },
      mapData: [], //地图数据点数据集合
      lineData: [], //地图线路******************
      currentMarkers: [], //当前显示的数据点组
      currentMarkersIndex: 0, //当前点击的数据点
      cluster: false, // 添加数据点插件
      returnViewShow: false, //返回景区按钮显示
      detailViewShow: false, //景点详情弹窗
      lineDetailShow: false, //线路详情窗口
      lineSelectShow: false, //线路选择窗口
      selectLineIndex: 0, //选择路线索引
      prevTargetMarker: null, //存储当前点击的marker，用于还原点击新图标时的样式控制。
      currentLinePoly: null, //存储当前线路
      isLineMarker: false, //判断点击的图标是否属于路线内
      currentLineMarkersIndex: 0, //当前点击的路线点索引值
      noticeData: [], //通知消息
      mapSetViewShow: false, //地图设置窗口
      setShowMap: false, //是否显示手绘地图
      setAutoPlay: false, //设置自动播放
      imageLayer: {}, //手绘地图对象
      isLBS: true, //是否支持定位
      currentPosition: null, //当前位置
      currentAudio: null, //当前播放的音频
      currentAudioState: false, //当前播放音频样式控制
      currentTargetAudio: null, //当前点击的音频
      setAutoLanguage: false, // 是否英文播放
      routeRequesting: false, //是否正在请求游览路线
      isticketPop: true, // 是否有优惠券的领取弹窗
      lange: "girl", // boy 男声 girl 女声 English 英语
      langepop: false,
      iscesuo: false, //判断是否是卫生间
      isuni_app: false, //判断是不是app
      istimer: "", //全局定时器
      vr_show: false, //展示vr
      xiao_show: false, //展示vr
      userImg: "",
      isYzsxh: false, // 扬州瘦西湖商户
    };
  },
  created () {
      const businessId = this.$route.query.business_id;
      // 瘦西湖商户
      if((businessId && businessId == '123') || location.origin == 'https://yzsxh.16u.com') {
        this.isYzsxh = true;
        this.setShowMap = true;
        console.log('location.origin', location.origin);
      }
    },
  mounted() {
    let that = this;
    const { sid, map_id } = this.$route.query;
    if (!sid) {
      this.getDefaultData();
    } else {
      this.sid = sid;
      this.map_id = map_id;
      this.getScenicNotice(sid);
      this.getScenicMapData(map_id);
    }
    that.isIphone();
    let historyLength = window.history.length;
    window.onhashchange = function() {
      const { pay, spot_id, point_id, line_id } = that.getHashParameters(
        window.location.hash
      );
      if (pay) {
        that.is_auth = true;
        that.sceniceModelShow = false;
      }
      if (spot_id && point_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
            item.markers.forEach((marker, mkIndex) => {
              if (marker.id == point_id) {
                that.map.setZoom(that.maxZoom);
                that.map.panTo(that.currentMarkers[mkIndex].position);
                that.markerEvent(that.currentMarkers[mkIndex], false);
              }
            });
          }
        });
      } else if (spot_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
          }
        });
      }
      if (line_id && that.lineData.length > 0) {
        that.sceniceModelShow = false;
        that.lineData.forEach((item, index) => {
          if (item.id == line_id) {
            that.showLineDetail(index);
          }
        });
      }
    };
    // 监听页面是否返回
    window.addEventListener("popstate", function(e) {
      if (historyLength < window.history.length) {
        historyLength = window.history.length;
      } else {
        util.openPage({ url: -1, hreftype: "navigateBack" });
      }
    });
  },
  watch: {
    $route() {
      this.reload = this.$route.query.point_id;
      if (this.$route.query.point_id || this.$route.query.spot_id) {
        this.getPageMarkerData();
      }
    },
    detailViewShow() {
      if (this.currentAudio) {
        this.currentAudioState = !this.currentAudio.paused;
      }
    },
  },
  methods: {
    // 获取页面数据点参数跳转
    getPageMarkerData() {
      let that = this;
      const { spot_id, point_id, line_id } = that.$route.query;
      if (spot_id && point_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
            item.markers.forEach((marker, mkIndex) => {
              if (marker.id == point_id) {
                that.map.setZoom(that.maxZoom);
                that.map.panTo(that.currentMarkers[mkIndex].position);
                that.markerEvent(that.currentMarkers[mkIndex], false);
              }
            });
          }
        });
      } else if (spot_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
          }
        });
      }
      if (line_id && that.lineData.length > 0) {
        that.sceniceModelShow = false;
        that.lineData.forEach((item, index) => {
          if (item.id == line_id) {
            that.showLineDetail(index);
          }
        });
      }
    },
    isIphone() {
      var isIphone = /iphone/gi.test(window.navigator.userAgent);
      var windowW = window.screen.width;
      var windowH = window.screen.height;
      var pixelRatio = window.devicePixelRatio;
      var isIPhoneX =
        isIphone &&
        pixelRatio &&
        pixelRatio === 3 &&
        windowW === 375 &&
        windowH === 812;
      var isIPhoneXSMax =
        isIphone &&
        pixelRatio &&
        pixelRatio === 3 &&
        windowW === 414 &&
        windowH === 896;
      var isIPhoneXR =
        isIphone &&
        pixelRatio &&
        pixelRatio === 2 &&
        windowW === 414 &&
        windowH === 896;

      if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
        // 底部增加line-main-pb样式
        this.setDataArr({
          bottomMargin: true,
        });
      }
    },
    getDefaultData() {
      let that = this;
      util
        .requests("post", {
          url: "map_data/getDefaultData",
        })
        .then((res) => {
          if (res.code) {
            that.sid = res.data.sid;
            that.map_id = res.data.map_id;
            that.getScenicNotice(res.data.sid);
            if(res.data.token){
              auth.setToken(res.data.token);
            }
            // that.getUserAvatar();
            setTimeout(() => {
              if (this.$route.query.type) {
                this.setDefaultDataCart();
              }
            }, 500);
            that.getScenicMapData(res.data.map_id);
          }else{
            util.showToast(res.msg);
            return false;
          }
        });
    },
    getScenicMapData(map_id) {
      Indicator.open("加载中...");
      let that = this;
      that.mapInitEnd = false;
      that.curMap_id = map_id;
      if (!map_id) {
        util.showToast("获取数据错误");
        return false;
      }
      util
        .requests("post", {
          url: "map_data/getData",
          data: { map_id: map_id },
        })
        .then((res) => {
          if (res.code) {
            let data = res.data;
            if (
              data.top_left_lng &&
              data.top_left_lat &&
              data.bottom_right_lng &&
              data.bottom_right_lat
            ) {
              that.bounds.SouthWest = [
                data.top_left_lng,
                data.bottom_right_lat,
              ];
              that.bounds.NorthEast = [
                data.bottom_right_lng,
                data.top_left_lat,
              ];
              let obj = {
                mapUrl: data.map_url,
                is_auth: data.is_auth,
                guideFlag: data.guide_flag,
                play_area: data.play_area,
                maxZoom: data.max_zoom > 0 ? data.max_zoom : 18,
                map_slice: data.map_slice,
                map_slice_url:
                  that.slice_root_src + data.map_slice_directory + "/",
              };
              
              if (that.sceniceModelShow && data.is_auth) {
                obj.sceniceModelShow = false;
              }
              that.setDataArr(obj);
            }
            that.getVisitRoute(map_id);
            if (res.data.scenic_info.route_switch) {
            }

            // 获取中心点位
            const middle_point = that.getMiddlePoint(
              data.top_left_lng,
              data.top_left_lat,
              data.bottom_right_lng,
              data.bottom_right_lat
            );

            that.setDataArr({
              scenicInfo: res.data.scenic_info,
              setAutoPlay:
                res.data.scenic_info.auto_play_switch == 1 ? true : false,
              mapCenter: [Number(middle_point.x), Number(middle_point.y)],
              mapData: res.data.spots,
            });
            document.title = res.data.scenic_info.name;

            //设置默认地图层级  map_init_type: 地图初始绘制
            let defaultZoom = data.map_init_type == 1 ? 12 : data.map_level;
            this.init(defaultZoom); //初始化地图
            if (that.mapUrl || that.map_slice === 1) {
              this.initDivMap(); //加载手绘地图
            }
            this.setDefaultData(); //初始化数据点
            this.getPageMarkerData(); //加载页面参数数据点
            setTimeout(() => {
              Indicator.close();
            }, 500);
          } else {
            Indicator.close();
            util.showToast("获取数据异常");
          }
        });
    },
    // 计算两点之间的中心坐标
    getMiddlePoint(lon1, lat1, lon2, lat2) {
      let x, y;
      let lonres = lon1 > lon2 ? (lon1 - lon2) / 2 : (lon2 - lon1) / 2;
      let latres = lat1 > lat2 ? (lat1 - lat2) / 2 : (lat2 - lat1) / 2;
      x =
        lon1 > lon2
          ? parseFloat(lon2) + parseFloat(lonres)
          : parseFloat(lon1) + parseFloat(lonres);
      y =
        lat1 > lat2
          ? parseFloat(lat2) + parseFloat(latres)
          : parseFloat(lat1) + parseFloat(latres);
      // console.log('中心点位>>', x, y);
      return { x: x, y: y };
    },
    GetDistance(lat1, lng1, lat2, lng2) {
      //就算2点的距离
      var radLat1 = (lat1 * Math.PI) / 180.0;
      var radLat2 = (lat2 * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000);
      return s;
    },
    getScenicNotice() {
      let that = this;
      util
        .requests("post", {
          url: "map_data/getScenicNotice",
          data: { sid: that.sid },
        })
        .then((res) => {
          if (res.code) {
            that.setDataArr({
              noticeData: res.data,
            });
          }
        });
    },
    checkPayStatus() {
      let that = this;
      util
        .requests("post", {
          url: "map_data/checkAuthStatus",
          data: { sid: that.sid },
        })
        .then((res) => {
          if (res.code) {
            that.setDataArr({
              is_auth: res.data,
            });
          }
        });
    },
    getPointInfo(_, isFirst, marker, isLine, item, icon, index) {
      let that = this;
      if (isFirst) {
        marker.setExtData({
          id: item.id,
          name: item.name,
          position: item.position,
          tel: item.tel,
          address: item.address,
          icon: isLine
            ? `<div class="amap-line-marker-icon">${index + 1}</div>`
            : that.divIcon(icon),
          audio: item.audioSrc,
          audio_boy: item.audioSrc,
          audio_girl: item.audioSrc_girl,
          audio_en: item.audioSrc_en,
          relation_toilet_id: item.relation_toilet_id,
          audio_duration: item.audio_duration,
          audio_en_duration: item.audio_en_duration,
          audio_girl_duration: item.audio_girl_duration,
          info: item.info,
          like: item.like,
          isLike: item.user_is_like,
          autoPlayAear: item.autoPlayAear,
          img: item.img ? item.img : defaultImg,
          is_charge: item.is_charge,
          arrIndex: index,
          relation_sid: item.relation_sid,
          relation_scenic_type: item.relation_scenic_type,
          relation_map_id: item.relation_map_id,
          vr_url: item.vr_url,
          wx_appid: item.wx_appid,
        });
        that.markerEvent(marker, isLine);
      }
      return false;
    },
    getVisitRoute(map_id) {
      let that = this;
      that.routeRequesting = true;
      util
        .requests("post", {
          url: "map_data/getScenicVisitRoute",
          data: { map_id: map_id },
        })
        .then((res) => {
          if (res.code) {
            that.setDataArr({
              lineData: res.data,
            });
            if (that.$route.query.line) {
              that.lineData.forEach((item, index) => {
                if (item.id == that.$route.query.line) {
                  that.showLineDetail(index);
                }
              });
            }
            if(that.$route.query.line_show && that.$route.query.line_show==1){
              that.$nextTick(function(){
                that.lineSelect()
                that.currentMarkersIndex=0;
              })
            }
          }
          that.routeRequesting = false;
        });
    },
    getUserAvatar() {
      let that = this;
      util
        .requests("post", {
          url: "map_data/getUserAvatar",
        })
        .then((res) => {
          if (res.code) {
            that.setDataArr({
              userImg: res.data,
            });
          }
        });
    },
    //更新引导页开关
    changeGuideFlag() {
      util.requests("post", {
        url: "map_data/changeGuideFlag",
      });
    },

    init(defaultZoom) {
      // 初始化地图
      let that = this;
      
      let map = new AMap.Map("map", {
        center: that.mapCenter,
        resizeEnable: true,
        showIndoorMap: false,
        zoom: defaultZoom,
        zooms: [3, 17]
      });
      
      this.map = map;
      that.mapEvent();
      that.mapInitEnd = true;
      // 自定义聚合点展示
      var _renderClusterMarker = function(context) {
        var div = document.createElement("div");
        div.className = "amap-marker-div-clusterMarkers";
        div.innerHTML = context.count;
        var p = document.createElement("p");
        p.innerText = context.markers[0].getExtData().name + "...等";
        div.appendChild(p);
        context.marker.setOffset(new AMap.Pixel(-15, -15)); // 坐标居中
        context.marker.setContent(div);
      };
      that.map.plugin(["AMap.MarkerClusterer"], function() {
        // 高德数据点聚合
        that.cluster = new AMap.MarkerClusterer(that.map, [], {
          averageCenter: false,
          minClusterSize: 3, // 3个起聚合
          renderClusterMarker: _renderClusterMarker,
        });
      });
    },

    // 监听地图事件
    mapEvent() {
      var that = this;
      var map = that.map;
      var TL, TR, BL, BR; //地图四角经纬度，用于判断是景区是否处于当前视口范围内
      TR = that.bounds.NorthEast;
      TL = [that.bounds.NorthEast[0], that.bounds.SouthWest[1]];
      BR = [that.bounds.SouthWest[0], that.bounds.NorthEast[1]];
      BL = that.bounds.SouthWest;

      // 地图移动事件
      map.on("moveend", function() {
        var vTL, vTR, vBL, vBR;
        var vBounds = map.getBounds();
        vTR = [vBounds.northeast.lng, vBounds.northeast.lat];
        vTL = [vBounds.northeast.lng, vBounds.southwest.lat];
        vBR = [vBounds.southwest.lng, vBounds.northeast.lat];
        vBL = [vBounds.southwest.lng, vBounds.southwest.lat];
        var isRingInRing1 = AMap.GeometryUtil.isRingInRing(
          [TL, TR, BR, BL],
          [vTL, vTR, vBR, vBL]
        );
        var isRingInRing2 = AMap.GeometryUtil.isRingInRing(
          [vTL, vTR, vBR, vBL],
          [TL, TR, BR, BL]
        );
        var doesRingRingIntersect = AMap.GeometryUtil.doesRingRingIntersect(
          [TL, TR, BR, BL],
          [vTL, vTR, vBR, vBL]
        );
        if (isRingInRing1 || isRingInRing2 || doesRingRingIntersect) {
          that.returnViewShow = false;
        } else {
          that.returnViewShow = true;
        }
        // 地图点击事件
        map.on("click", function() {
          if (that.prevTargetMarker && !that.isLineMarker) {
            that.markerStyleReturn();
            that.detailViewShow = false;
          }
        });
      });
    },

    // 数据点样式还原
    markerStyleReturn() {
      var that = this;
      that.prevTargetMarker.setContent(that.prevTargetMarker.getExtData().icon);
      if (that.lineDetailShow) {
        that.prevTargetMarker.setOffset(new AMap.Pixel(-13, -13));
        that.prevTargetMarker.setLabel({ content: null, direction: "bottom" });
      } else {
        that.prevTargetMarker.setOffset(new AMap.Pixel(-15, -30));
        that.prevTargetMarker.setLabel({ direction: "bottom" });
      }
      that.vr_show = false;
      that.xiao_show = false;
      that.prevTargetMarker.setzIndex(110);
      that.prevTargetMarker = null;
    },

    // 加载手绘地图
    initDivMap() {
      var that = this;
      var imageLayer;
      if (that.map_slice == 1) {
        imageLayer = new AMap.TileLayer({
          zIndex: 100,
          getTileUrl: function(x, y, z) {
            return (
              that.map_slice_url + z + "/" + x + "_" + y + ".png"
            );
          },
        });
        that.setShowMap = true;
      } else {
        imageLayer = new AMap.ImageLayer({
          url: that.mapUrl,
          bounds: new AMap.Bounds(that.bounds.SouthWest, that.bounds.NorthEast),
          zIndex: 100,
          // visible: that.setShowMap
        });
      }

      that.imageLayer = imageLayer;
      this.map.add(imageLayer);
      // that.setShowMap = true;
    },

    // 设置地图初始化数据
    setDefaultData() {
      var defaultData = this.mapData[0];
      this.setMarkers(
        defaultData.markers,
        defaultData.icon,
        defaultData.defaultImg,
        0
      );
    },

    // 设置数据点
    setMarkers(markersArr, icon, defaultImg, index) {
      var that = this;
      that.currentMarkersIndex = index;
      if (that.istimer) {
        clearInterval(that.istimer);
        that.istimer = "";
      }
      if (that.currentMarkers.length > 0) {
        that.cluster.removeMarkers(that.currentMarkers);
      }
      that.currentMarkers = [];
      markersArr.forEach((item, pIndex) => {
        if (!item.position[0] || !item.position[1]) {
          return true;
        }
        var marker = new AMap.Marker({
          position: item.position,
          content: that.divIcon(icon),
          offset: new AMap.Pixel(-15, -30),
          zIndex: 110,
          clickable: true,
          topWhenClick: true,
          extData: {
            id: item.id,
            name: item.name,
            position: item.position,
            tel: item.tel,
            address: item.address,
            icon: that.divIcon(icon),
            audio: item.audioSrc,
            audio_boy: item.audioSrc,
            audio_girl: item.audioSrc_girl,
            relation_toilet_id: item.relation_toilet_id,
            audio_en: item.audioSrc_en,
            audio_duration: item.audio_duration,
            audio_en_duration: item.audio_en_duration,
            audio_girl_duration: item.audio_girl_duration,
            info: item.info,
            like: item.like,
            isLike: item.user_is_like,
            autoPlayAear: item.autoPlayAear,
            img: item.img ? item.img : defaultImg,
            is_charge: item.is_charge,
            arrIndex: pIndex,
            relation_sid: item.relation_sid,
            relation_scenic_type: item.relation_scenic_type,
            relation_map_id: item.relation_map_id,
            vr_url: item.vr_url,
            wx_appid: item.wx_appid,
          },
          label: {
            content: item.name,
            direction: "bottom",
          },
        });
        marker.on("click", function() {
          if (marker.getExtData().audio || item.audio) {
            that.markerEvent(marker, false);
          } else {
            that.getPointInfo(item.id, true, marker, false, item, icon, pIndex);
          }
        });
        that.currentMarkers.push(marker);
      });
      var timer = setInterval(function() {
        if (that.cluster) {
          that.cluster.addMarkers(that.currentMarkers);
          clearInterval(timer);
        }
      }, 200);
      this.navMoreShow = false;
      that.map.setFitView(that.currentMarkers, false);
    },

    // 设置地图小车初始化数据
    setDefaultDataCart() {
      const ts = this;
      if (ts.istimer) {
        return;
      }
      ts.getCart();
      ts.istimer = setInterval(() => {
        ts.getCart();
      }, 15000);
    },
    getCart() {
      const ts = this;
      util
        .requests("post", {
          url: "Information/getTourbusList",
        })
        .then((res) => {
          if (res.code) {
            if (res.data.length == 0) {
              Toast("暂无车辆在行驶中");
              return;
            }
            ts.setMarkersCart(res.data, "", "", 0);
          }
        });
    },

    // 设置小车数据点
    setMarkersCart(markersArr, icon, defaultImg, index) {
      var that = this;
      that.currentMarkersIndex = "";
      if (that.currentMarkers.length > 0) {
        that.cluster.removeMarkers(that.currentMarkers);
      }
      that.currentMarkers = [];
      markersArr.forEach((item, pIndex) => {
        if (!item.LAST_LATITUDE || !item.LAST_LONGITUDE) {
          return true;
        }
        var marker = new AMap.Marker({
          position: [item.LAST_LONGITUDE, item.LAST_LATITUDE],
          content: that.divIcons(icon),
          offset: new AMap.Pixel(-15, -30),
          zIndex: 110,
          clickable: true,
          topWhenClick: true,
          extData: {
            id: item.id,
            name: item.CARNO,
            position: [item.LAST_LONGITUDE, item.LAST_LATITUDE],
            icon: that.divIcons(icon),
          },
          label: {
            content: item.CARNO,
            direction: "bottom",
          },
        });
        that.currentMarkers.push(marker);
      });
      var timer = setInterval(function() {
        if (that.cluster) {
          that.cluster.addMarkers(that.currentMarkers);
          clearInterval(timer);
        }
      }, 200);
      this.navMoreShow = false;
    },

    // 点击数据点图标事件
    markerEvent(e, isLineMarker) {
      var that = this;
      that.detailViewShow = false;
      that.isLineMarker = isLineMarker;
      if (that.prevTargetMarker) {
        that.markerStyleReturn();
      }
      var targetMarker = e;
      that.prevTargetMarker = e;
      targetMarker.setzIndex(120);
      targetMarker.setContent(`<div class="amap-current-target-marker">
      <img src="${targetMarker.getExtData().img}">
    </div>`);
      targetMarker.setOffset(new AMap.Pixel(-30, -70));
      if (that.isLineMarker) {
        targetMarker.setLabel({
          content: targetMarker.getExtData().name,
          direction: "top",
        });
        that.currentLineMarkersIndex = targetMarker.getExtData().arrIndex;
      } else {
        targetMarker.setLabel({ direction: "top" });
      }
      that.map.panTo(e.getPosition());
      // 获取marker数据
      setTimeout(() => {
        that.detailViewShow = true;
        if (isLineMarker) {
          that.navScroll();
        }
      }, 100);
    },
    // 锚点滚动
    navScroll() {
      setTimeout(() => {
        var scrollBox = document.querySelector(".line-marker-top-nav");
        var active = document.querySelector(".line-top-nav-item.active");
        var star_scroll = scrollBox.scrollLeft;
        var end_scroll = active.offsetLeft - scrollBox.clientWidth / 2;
        var step = 0;
        if (end_scroll >= star_scroll) {
          step = (end_scroll - star_scroll) / 10;
        } else {
          step = -(star_scroll - end_scroll) / 10;
        }
        var time = 0;
        var animation;
        slide();
        function slide() {
          animation = requestAnimationFrame(slide);
          time += 1;
          scrollBox.scrollLeft += step;
          if (time >= 10) {
            cancelAnimationFrame(animation);
          }
        }
      }, 100);
    },

    // 自定义ICON
    divIcon(iconUrl) {
      var divIcon = `<div class="amap-marker-div-marker">
      <img src="${iconUrl}"></div>`;
      return divIcon;
    },

    // 返回景区
    returnCenter() {
      var that = this;
      that.map.setCenter(that.mapCenter);
      that.map.setFitView(that.currentMarkers, false);
    },

    // 显示更多模态框
    moreModelToogle() {
      this.navMoreShow = !this.navMoreShow;
    },

    //跳转小程序
    toAsk() {
      let that = this;
      util.openPage({
        url: "/pages/serve/form?sid=" + that.curSid,
      });
    },
    toNotice() {
      let that = this;
      if (that.currentPosition) {
        var url =
          "/pages/tabBar/notices/index?lat=" +
          that.currentPosition[1] +
          "&lng=" +
          that.currentPosition[0];
      } else {
        var url = "/pages/tabBar/notices/index";
      }
      if (that.isuni_app) {
        uni.webView.navigateTo({
          url: url,
        });
      } else {
        util.openPage({
          url: url,
        });
      }
    },
    toList() {
      let that = this;
      if (that.currentPosition) {
        var url =
          "/list?sid=" +
          that.sid +
          "&map_id=" +
          that.curMap_id +
          "&lat=" +
          that.currentPosition[1] +
          "&lng=" +
          that.currentPosition[0];
      } else {
        var url = "/list?sid=" + that.sid + "&map_id=" + that.curMap_id;
      }
      if (that.isuni_app) {
        uni.webView.navigateTo({
          url: url,
        });
      } else {
        util.openPage({
          url: url,
        });
      }
    },
    openPage: util.openPage,
    openPages(e) {
      const ts = this;
      if (ts.currentPosition) {
        ts.$router.push({
          path: "/search",
          query: {
            mapid: e,
            lat: ts.currentPosition[1],
            lng: ts.currentPosition[0],
          },
        });
      } else {
        ts.$router.push({ path: "/search", query: { mapid: e } });
      }
    },

    // 选择线路
    lineSelect() {
      var that = this;
      if (that.routeRequesting) {
        let limit_time = 6;
        Toast.loading({
          message: "加载中",
        });
        let t = setInterval(function() {
          if (that.routeRequesting && limit_time > 0) {
            --limit_time;
          } else {
            clearInterval(t);
          }
        }, 500);
        if (limit_time <= 0) {
          Toast.fail("获取推荐路线错误，请稍后再试");
          return false;
        }
      }
      if (that.lineData.length <= 0) {
        Toast({
          message: "暂无推荐路线",
        });
      } else {
        that.lineSelectShow = true;
      }
    },

    // 清除所有覆盖物数据
    clearAllLayer() {
      var that = this;
      // 清除旧路线以及旧数据点
      if (that.currentLinePoly) {
        that.map.remove(that.currentLinePoly);
      }
      if (that.currentMarkers.length > 0) {
        that.map.remove(that.currentMarkers);
        if (that.cluster) {
          that.cluster.removeMarkers(that.currentMarkers);
        }
        that.currentMarkers = [];
      }
    },

    // 显示路线
    showLineDetail(index) {
      var that = this;
      that.lineSelectShow = false;
      that.lineDetailShow = true;
      that.selectLineIndex = index;

      // 清除所有覆盖物数据
      that.clearAllLayer();

      // 画路线
      that.currentLinePoly = new AMap.Polyline({
        path: that.lineData[index].path,
        isOutline: true,
        borderWeight: 1,
        outlineColor: "#fff",
        strokeWeight: 4, // 线条宽度，默认为 1
        strokeColor: "#5197F7", // 线条颜色
        showDir: true,
        lineJoin: "round", // 折线拐点连接处样式
      });

      // 将折线添加至地图实例
      that.map.add(that.currentLinePoly);

      // 添加地图数据点
      that.lineData[index].markers.forEach((item, index) => {
        var marker = new AMap.Marker({
          position: item.position,
          content: `<div class="amap-line-marker-icon">${index + 1}</div>`,
          offset: new AMap.Pixel(-13, -13),
          zIndex: 110,
          clickable: true,
          extData: {
            id: item.id,
            name: item.name,
            position: item.position,
            tel: item.tel,
            address: item.address,
            icon: `<div class="amap-line-marker-icon">${index + 1}</div>`,
            info: item.info,
            audio: item.audioSrc,
            audio_boy: item.audioSrc,
            audio_girl: item.audioSrc_girl,
            relation_toilet_id: item.relation_toilet_id,
            audio_en: item.audioSrc_en,
            audio_duration: item.audio_duration,
            audio_en_duration: item.audio_en_duration,
            audio_girl_duration: item.audio_girl_duration,
            like: item.like,
            isLike: item.user_is_like,
            autoPlayAear: item.autoPlayAear,
            img: item.img ? item.img : defaultImg,
            is_charge: item.is_charge,
            arrIndex: index,
            relation_sid: item.relation_sid,
            relation_scenic_type: item.relation_scenic_type,
            relation_map_id: item.relation_map_id,
            vr_url: item.vr_url,
            wx_appid: item.wx_appid,
          },
        });
        marker.getNewPayData = function() {
          if (marker.getExtData().audio || item.audio) {
            that.markerEvent(marker, true);
          } else {
            that.getPointInfo(item.id, true, marker, true, item, null, index);
          }
        };
        marker.on("click", function() {
          if (marker.getExtData().audio || item.audio) {
            that.markerEvent(marker, true);
          } else {
            that.getPointInfo(item.id, true, marker, true, item, null, index);
          }
        });
        that.currentMarkers.push(marker);
      });

      that.map.add(that.currentMarkers);
      that.map.setFitView(that.currentMarkers);
    },

    autoPlayToogle() {
      var that = this;
      that.$refs.detailview.audioPlayToogle();
    },

    // 返回全域地图
    backMain() {
      this.detailViewShow = false;
      this.lineDetailShow = false;
      this.getScenicNotice(this.sid);
      this.getScenicMapData(this.map_id);
    },

    getHashParameters() {
      var params = {};
      let _hash_params_idx = (location.hash || "").indexOf("?");
      if (_hash_params_idx != -1) {
        var arr = location.hash.substring(_hash_params_idx + 1).split("&");
        for (var i = 0; i < arr.length; i++) {
          var data = arr[i].split("=");
          if (data.length == 2) {
            params[data[0]] = data[1];
          }
        }
      }
      return params;
    },
    // 自定义小车ICON
    divIcons(iconUrl) {
      var divIcon = `<div class="amap-marker-div-markers">
      </div>`;
      return divIcon;
    },
    audioToggle() {
      var that = this;
      if (that.currentAudio.paused) {
        that.currentAudio.play();
        that.currentAudioState = true;
      } else {
        that.currentAudio.pause();
        that.currentAudioState = false;
      }
    },
    goIn(e) {
      const that = this;
      if (e == 1) {
        that.openPage({
          url: "/pages/web/index?id=" + that.prevTargetMarker.getExtData().id,
        });
      } else {
        that.openPage({
          url:
            "/pages/change/index?type=" +
            e +
            "&id=" +
            that.prevTargetMarker.getExtData().id,
        });
      }
    },
    // 瘦西湖商户跳转AR
    jumpOtherMiniPro(extraData = {}) {
      let params = {
        appId: 'wxb5755c5a7c7cf706', 
        path: 'pages/homepage/homepage',
        extraData,    //业务参数
        envVersion:'release'
      }
      console.log(params.path);
      wx.miniProgram.navigateTo({
        url:`/pagesContent/jump-app/index?data=${encodeURIComponent(JSON.stringify(params))}`
      })
    }
  },
};
</script>

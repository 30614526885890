<template>
  <div :class="['notice-view flex', swiperShow ? '' : 'hide']">
    <div class="notice-message-box flex ac">
      <div class="notice" @click.stop="closeNotice"></div>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in noticeData"
          :key="index"
        >
          <div class="notice-title" :data-id="item.id">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="notice-close-box flex ac">
      <div
        class="notice-close"
        v-if="swiperShow"
        @click="swiperShow = false"
      ></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
var util = require("@/utils/util");

export default {
  name: "Notice",
  props: {
    noticeData: Array,
    toNotice: Function,
  },
  data() {
    return {
      swiperShow: true,
      isclose: false,
    };
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      loop: true,
      preventLinksPropagation: false,
      autoplay: {
        delay: 3000,
      },
      direction: "vertical",
    });
    mySwiper.on("click", (e) => {
      this.gointo(e.target.getAttribute("data-id"));
    });
  },
  methods: {
    closeNotice() {
      this.swiperShow = !this.swiperShow;
    },
    gointo(id) {
      this.$router.push({ path: "/noticeDetail", query: { id } });
    },
  },
};
</script>

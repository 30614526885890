<template>
    <div class="scenice-model">
        <div class="bg"></div>
        <div class="main">
            <div class="model-container">
                <div class="close-model" @click="modelClose"></div>
                <div class="level" v-if="scenicInfo.level_type_name">{{scenicInfo.level_type_name}}</div>
                <div class="info">
                    <div class="img-view">
                        <img :src="scenicInfo.logo">
                        <span class="free-tip">免费试听</span>
                        <i :class="['audio-btn',isPlaying?'pause':'play']" @click="modelAudioPlay"></i>
                    </div>
                    <div class="text-view">
                        <div class="title">{{scenicInfo.name}}</div>
                        <div class="contant">{{scenicInfo.desc}}</div>
                    </div>
                </div>
                <div class="address">景区地址：{{scenicInfo.address}}</div>
                <div class="open-time">开放时间（以景区实际为准）：<br>{{scenicInfo.open_time}}</div>
                <div class="btns">
                    <div class="act-btn" @click="openPage({url: '/pages/activation/index'})">授权码激活</div>
                    <div class="buy-btn" @click="openPage({url: '/pages/pay/pay?sid='+scenicInfo['id']})">优惠购买</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var util = require("@/utils/util");
export default {
    name:'SceniceModel',
    props: {
        scenicInfo: Object
    },
    data(){
        return{
            isPlaying:false
        }
    },
    mounted(){
        
    },
    methods:{
        modelAudioPlay(){
            var that = this;
            if(that.$parent.currentAudio&&that.isPlaying){
                that.$parent.currentAudio.pause();
                that.isPlaying = false;
            }else if(that.$parent.currentAudio&&!that.isPlaying){
                that.$parent.currentAudio.play();
                that.isPlaying = true;
                that.$parent.currentAudio.addEventListener("ended", function() {
                    that.isPlaying = false;
                });
            }else{
                that.$parent.currentAudio = new Audio();
                that.$parent.currentAudio.src = that.scenicInfo.voice_url;
                that.$parent.currentAudio.load();
                that.$parent.currentAudio.addEventListener("canplay", function() {
                    that.$parent.currentAudio.play();
                    that.isPlaying = true;
                });
                that.$parent.currentAudio.addEventListener("ended", function() {
                    that.isPlaying = false;
                });
            }
        },
        modelClose(){
            this.$parent.sceniceModelShow = false;
            this.isPlaying = false;
            this.$parent.currentAudio.pause();
            this.$parent.currentAudio = null;
        },
        openPage: util.openPage,
    }
}
</script>



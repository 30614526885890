<template>
  <div class="presentation-view">
    <div class="bg" @click="stepNext"></div>
    <div class="setp setp1" v-if="step == 1" @click="stepNext">
      点击标签<br />查看对应地点
    </div>
    <div class="setp setp2" v-if="step == 2" @click="stepNext">
      点击设置切换<br />手绘和高德地图
    </div>
    <div class="setp setp3" v-if="step == 3" @click="stepNext">
      点击气泡图标<br />收听语音讲解
    </div>
    <div class="setp setp4" v-if="step == 4" @click="stepNext">
      点击上方，选择浮窗能更快捷回到桌面哦！
    </div>
  </div>
</template>
<script>
export default {
  name: "Location",
  props: {
    guideFlag: Number,
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    stepNext() {
      var that = this;
      if (that.step < 4) {
        that.step++;
      } else {
        that.$parent.guideFlag = 1;
        that.$parent.changeGuideFlag();
      }
    },
  },
};
</script>

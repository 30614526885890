<template>
    <div>
        <div class="coupon-send scale" style="backgroundImage: url('https://lubao.16u.cc/static/images/coupon.png');"></div>
        <div class="punch" style="backgroundImage: url('https://lubao.16u.cc/static/images/punch.png');"></div>
    </div>
</template>

<script>
export default {
    name: "Coupon",
    props: {

    },
    data() {
        return {
        };
    },
    mounted() {

    },
    methods:{
  
    },
};
</script>
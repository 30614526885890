<template>
  <div class="detail-view animaton" :style="'bottom:' + slideBottom + 'px'">
    <div
      class="main"
      id="slideView"
      @touchstart.capture="slideStart"
      @touchmove.capture="slideMove"
      @touchend.capture="slideEnd"
      @mousedown.capture="slideStart"
      @mousemove.capture="slideMove"
      @mouseup.capture="slideEnd"
    >
      <div class="exit-line" v-if="isLineMarker" @click="returnLine">
        返回主路线
      </div>
      <div class="line-detail-item">
        <div class="flex">
          <div class="scenic-img-box">
            <img :src="markerData.img" class="scenic-img" />
          </div>
          <div class="scenic-info">
            <div class="scenic-name only">{{ markerData.name }}</div>
            <div class="scenic-address only" v-if="markerData.address">{{markerData.address}}</div>
            <a class="scenic-tel" :href="'tel:'+markerData.tel" v-if="markerData.tel">{{markerData.tel}}</a>
            <div
              :class="[
                'scenic-distance',
                'flex',
                'ac',
                !markerData.audio ? 'mt15' : '',
              ]"
            >
              <div class="distance-icon" />
              <div class="distance-text">距离你</div>
              <div class="distance-num">
                {{ dis < 100 ? 0 : (dis / 1000).toFixed(1) }}
              </div>
              <div class="distance-company">
                Km
              </div>
            </div>
            <!-- 小号进度条 -->
            <div class="progress-box" v-if="markerData.audio && !markerData.tel && !markerData.address">
              <div class="flex ac progress-range-box">
                <div class="range-prev" @click="audioBack" />
                <mt-range
                  class="progress-range"
                  :value="playDur"
                  :min="0"
                  :max="dur"
                  :bar-height="2"
                />
                <div class="range-next" @click="audioForward" />
              </div>
              <div class="flex jb progress-time">
                <div>
                  {{
                    playDur < 59
                      ? "00:" +
                        (playDur < 10
                          ? "0" + playDur.toFixed(0)
                          : playDur.toFixed(0))
                      : Math.floor(playDur / 60).toFixed(0) +
                        ":" +
                        (playDur % 60 < 10
                          ? "0" + (playDur % 60).toFixed(0)
                          : (playDur % 60).toFixed(0))
                  }}
                </div>
                <div>
                  {{
                    dur < 60
                      ? "00:" + (dur < 10 ? "0" + dur : dur)
                      : (Math.floor(dur / 60).toFixed(0) < 10
                          ? "0" + Math.floor(dur / 60).toFixed(0)
                          : Math.floor(dur / 60).toFixed(0)) +
                        ":" +
                        (dur % 60 < 10 ? "0" + (dur % 60) : dur % 60)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 大号进度条 -->
        <div class="progress-box big" v-if="markerData.audio && (markerData.tel || markerData.address)">
          <div class="flex ac progress-range-box">
            <div class="range-prev" @click="audioBack" />
            <mt-range
              class="progress-range"
              :value="playDur"
              :min="0"
              :max="dur"
              :bar-height="2"
            />
            <div class="range-next" @click="audioForward" />
          </div>
          <div class="flex jb progress-time">
            <div>
              {{
                playDur < 59
                  ? "00:" +
                    (playDur < 10
                      ? "0" + playDur.toFixed(0)
                      : playDur.toFixed(0))
                  : Math.floor(playDur / 60).toFixed(0) +
                    ":" +
                    (playDur % 60 < 10
                      ? "0" + (playDur % 60).toFixed(0)
                      : (playDur % 60).toFixed(0))
              }}
            </div>
            <div>
              {{
                dur < 60
                  ? "00:" + (dur < 10 ? "0" + dur : dur)
                  : (Math.floor(dur / 60).toFixed(0) < 10
                      ? "0" + Math.floor(dur / 60).toFixed(0)
                      : Math.floor(dur / 60).toFixed(0)) +
                    ":" +
                    (dur % 60 < 10 ? "0" + (dur % 60) : dur % 60)
              }}
            </div>
          </div>
        </div>
        <div class="flex scenic-operation-box">
          <div
            class="flex ac jc scenic-operation-item"
            v-if="markerData.vr_url"
            @click="handlePanorama(markerData.vr_url)"
          >
            <template
              v-if="
                !markerData.audio &&
                  !(markerData.position && markerData.position.length === 2)
              "
            >
              <div class="flex only-audio ac jc">
                <div class="panorama icon-item" />
                <div class="operation-name">全景</div>
              </div>
            </template>
            <template v-else>
              <div class="panorama icon-item" />
              <div class="operation-name">全景</div>
            </template>
          </div>
          <div
            class="flex ac jc scenic-operation-item"
            @click="audioPlayToogle"
            v-if="markerData.audio"
          >
            <template
              v-if="
                !markerData.vr_url &&
                  !(markerData.position && markerData.position.length === 2)
              "
            >
              <div class="flex only-audio ac jc">
                <div :class="['icon-item', playing ? 'play' : 'pause']" />
                <div class="operation-name">导览讲解</div>
              </div>
            </template>
            <template v-else>
              <div :class="['icon-item', playing ? 'play' : 'pause']" />
              <div class="operation-name">导览讲解</div>
            </template>
          </div>
          <div
            class="flex ac jc scenic-operation-item"
            v-if="markerData.position && markerData.position.length === 2 && !$parent.isYzsxh"
            @click="handleGoTo(markerData.position)"
          >
            <template v-if="!markerData.vr_url && !markerData.audio">
              <div class="flex only-audio ac jc">
                <div class="guide icon-item" />
                <div class="operation-name">前往</div>
              </div>
            </template>
            <template v-else>
              <div class="guide icon-item" />
              <div class="operation-name">前往</div>
            </template>
          </div>
          <div class="flex ac jc scenic-operation-item" v-if="$parent.isYzsxh" @click="$parent.jumpOtherMiniPro()">
            <div class="ar icon-item" />
            <div class="operation-name">AR</div>
          </div>
        </div>
      </div>
      <div
        :class="['scenic-content', 'marker-detail-view']"
        v-if="markerData.info"
      >
        <div class="scenic-introduce">详情介绍</div>
        <collapse :show-height="104" textIndent="0em" toggle>
          <div class="introduce-box">
            <div v-html="markerData.info" class="introduce" />
          </div>
        </collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import Collapse from "@/components/Collapse.vue";
var util = require("@/utils/util");
export default {
  name: "detailView",
  components: { Indicator, Collapse },
  props: {
    isLineMarker: {
      type: Boolean,
      default: false,
    },
    markerData: {
      type: Object,
      default: null,
    },
    isAuth: Boolean,
  },
  data() {
    return {
      sid: this.$parent.sid,
      is_auth: this.$parent.is_auth,
      slideBottom: 0,
      currentBottom: 0,
      mainHeight: 0,
      viewHeight: 0,
      slideShow: false,
      slideStated: false,
      isAanimation: false,
      slideStartY: 0,
      slideMovedY: 0,
      slideEndY: 0,
      slideStartTime: 0,
      slideEndTime: 0,
      speed: 0,
      moreShow: false,
      modelViewShow: false,
      dis: 0,
      playing: false,
      audioLoading: false,
      currentAudio: this.$parent.currentAudio,
      currentTargetAudio: this.$parent.currentTargetAudio,
      dur: this.markerData.audio_duration,
      playDur: 0,
      curTime: null,
      punch_open: false,
      punch_success: false,
      punch_success_show: false,
      iscesuo: false,
      cesuo_info: "",
      isOpen: true,
    };
  },
  watch: {
    playDur: function(newVal, oldVal) {
      var that = this;
      if (Math.abs(newVal - oldVal) > 9) {
        if (
          that.$parent.currentAudio &&
          that.$parent.currentAudio.src == that.markerData.audio
        ) {
          that.$parent.currentAudio.currentTime = newVal;
        } else {
          clearInterval(this.curTime);
        }
      }
    },
    markerData: {
      handler(newV) {
        const { position } = newV;
        this.getDistance(this.$parent.currentPosition, position);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    var that = this;
    if (
      that.$parent.mapData[that.$parent.currentMarkersIndex].type == "卫生间"
    ) {
      that.$parent.iscesuo = false;
      // that.$parent.iscesuo = true;
      // that.getToilet();
    } else {
      that.$parent.iscesuo = false;
    }
    console.log('that.markerData',that.markerData)
    that.markerData.audio = that.markerData.audio_boy;
    that.dur = that.markerData.audio_duration;
    // if (that.$parent.lange == "girl") {
    //   if (that.markerData.audio_girl && that.markerData.audio_girl != "") {
    //     that.markerData.audio = that.markerData.audio_girl;
    //     that.dur = that.markerData.audio_girl_duration;
    //   } else {
    //     that.markerData.audio = "";
    //   }
    // } else if (that.$parent.lange == "English") {
    //   if (that.markerData.audio_en && that.markerData.audio_en != "") {
    //     that.markerData.audio = that.markerData.audio_en;
    //     that.dur = that.markerData.audio_en_duration;
    //   } else {
    //     that.markerData.audio = "";
    //   }
    // } else {
    //   that.markerData.audio = that.markerData.audio_boy;
    //   that.dur = that.markerData.audio_duration;
    // }
  },
  beforeDestroy() {
    if (this.curTime) {
      clearInterval(this.curTime);
      this.curTime = null;
    }
    if (this.$parent.currentAudio) {
      this.$parent.currentAudio.pause();
      this.$parent.currentAudio = new Audio();
    }
  },
  mounted() {
    var that = this;
    that.viewHeight = document.querySelector("html").clientHeight - 70;
    var mainHeight = document.querySelector("#slideView");
    if (mainHeight) {
      // that.mainHeight = mainHeight.clientHeight;
    }
    if (that.markerData.vr_url) {
      that.$parent.vr_show = true;
    }
    if (that.markerData.wx_appid) {
      that.$parent.xiao_show = true;
    }
    // 获取当前播放音频的时间
    that.currentTargetAudio = new Audio();
    that.currentTargetAudio.src = that.markerData.audio;

    // 判断当前点击是否为当前播放音频
    if (that.currentAudio) {
      if (that.currentTargetAudio.src != that.currentAudio.src) {
        that.playing = false;
      } else {
        if (that.currentAudio.paused) {
          that.curTime = setInterval(() => {
            that.playDur = that.$parent.currentAudio.currentTime;
          }, 200);
          that.playing = false;
        } else {
          that.curTime = setInterval(() => {
            if (that.$parent.currentAudio) {
              that.playDur = that.$parent.currentAudio.currentTime;
            } else {
              clearInterval(that.curTime);
            }
          }, 200);
          // that.playing = true;
        }
      }
    }

    setTimeout(() => {
      document.querySelector(".detail-view").className = "detail-view";
    }, 300);

    window.onhashchange = function(e) {
      let params = that.$parent.getHashParameters(window.location.hash);
      var pay = params.pay;
      if (pay) {
        that.getPointInfo(that.markerData.id);
        that.$parent.sceniceModelShow = false;
      }
    };
  },
  methods: {
    handleGoTo(position) {
      // const url = `https://uri.amap.com/marker?position=${position.join(
      //   ","
      // )}&name=${this.markerData.name}`;
      // window.open(url);
      let url = '/pages/navigation/navigation?name='+ this.markerData.name + '&lat='+ position[1] + '&lng='+ position[0];
      util.openPageMini({url});
    },
    handlePanorama(vr_url) {
      window.open(vr_url);
    },
    getDistance(arr1, arr2) {
      let that = this;
      if (arr1) {
        that.dis = AMap.GeometryUtil.distance(arr1, arr2);
      }
    },
    getPointInfo(pid) {
      let that = this;
      Indicator.open();
      util
        .requests("post", {
          url: "map_data/getPointData",
          data: { sid: that.$parent.sid, point_id: pid },
        })
        .then((res) => {
          if (res.code) {
            setTimeout(() => {
              Indicator.close();
            }, 200);
            if (res.data == 1 && res.msg == "no pay") {
              //  that.$parent.sceniceModelShow = true;
            } else {
              if (that.setAutoLanguage && res.data.audioSrc_en) {
                that.markerData.audio = res.data.audioSrc_en;
                that.currentTargetAudio = new Audio();
                that.currentTargetAudio.src = res.data.audioSrc_en;
              } else {
                that.markerData.audio = res.data.audioSrc;
                that.currentTargetAudio = new Audio();
                that.currentTargetAudio.src = res.data.audioSrc;
              }
              //只有三个数据，数据点图片（res.data.img）、中文语音路径（res.data.audioSrc）、英文语音路径（res.data.audioSrc_en）
            }
          } else {
            Indicator.close();
            util.showToast(res.msg);
          }
        });
    },
    getToilet() {
      //获取厕所信息
      const ts = this;
      if (ts.markerData.relation_toilet_id == 0) {
        ts.$parent.iscesuo = false;
        return;
      }
      util
        .requests("post", {
          url: "toilet/checkAvailableData",
          data: { toi_poi_id: ts.markerData.relation_toilet_id },
        })
        .then((res) => {
          if (res.code) {
            ts.cesuo_info = res.data;
            ts.iscesuo = true;
          } else {
            Toast({
              message: res.msg,
            });
          }
        });
    },
    toSenice(sid, type, map_id) {
      if (type == 2) {
        util.openPage({ url: "/pagesA/audiolist/audiolist?sid=" + sid });
      } else if (type == 0) {
        util.openPage({ url: "/pagesA/audiolist/showroom?sid=" + sid });
      } else {
        this.$parent.getScenicMapData(map_id);
        this.$parent.getScenicNotice(sid);
        this.$parent.detailViewShow = false;
      }
    },
    audioBack() {
      var that = this;
      if (
        that.$parent.currentAudio &&
        that.$parent.currentAudio.src == that.markerData.audio
      ) {
        if (that.playDur < 10) {
          that.playDur = 0;
        } else {
          that.playDur -= 10;
        }
      }
    },
    audioForward() {
      var that = this;
      if (
        that.$parent.currentAudio &&
        that.$parent.currentAudio.src == that.markerData.audio
      ) {
        if (that.playDur + 10 > that.dur) {
          that.playDur = that.dur;
        } else {
          that.playDur += 10;
        }
      }
    },
    audioPlay() {
      var that = this;
      if (that.$parent.currentAudio.src == that.currentTargetAudio.src) {
        that.$parent.currentAudio.play();
        that.curTime = setInterval(() => {
          that.playDur = that.$parent.currentAudio.currentTime;
        }, 200);
        that.playing = true;
        that.$parent.currentAudio.addEventListener("ended", function() {
          clearInterval(that.curTime);
          that.playing = false;
          that.$parent.currentAudio.currentTime = 0;
          that.playDur = 0;
          that.$parent.currentAudio = null;
        });
      } else {
        that.$parent.currentAudio.src = that.currentAudio.src;
        that.audioLoading = true;
        that.$parent.currentAudio.load();
        that.$parent.currentAudio.addEventListener("canplay", function() {
          that.$parent.currentAudio.play();
          that.curTime = setInterval(() => {
            that.playDur = that.$parent.currentAudio.currentTime;
          }, 500);
          that.playing = true;
          that.audioLoading = false;
        });
        that.$parent.currentAudio.addEventListener("ended", function() {
          clearInterval(that.curTime);
          that.playing = false;
          that.$parent.currentAudio.currentTime = 0;
          that.playDur = 0;
          that.$parent.currentAudio = null;
        });
      }
    },
    audioPlayToogle() {
      var that = this;
      if (that.$parent.currentAudio) {
        if (that.$parent.currentAudio.src == that.markerData.audio) {
          if (that.$parent.currentAudio.paused) {
            that.audioPlay();
          } else {
            that.$parent.currentAudio.pause();
            that.playing = false;
          }
        } else {
          that.$parent.currentAudio.src = that.markerData.audio;
          that.audioPlay();
        }
      } else {
        that.$parent.currentAudio = new Audio();
        that.$parent.currentAudio.src = that.markerData.audio;
        that.audioPlay();
      }
    },

    likeEvent() {
      let that = this;
      if (that.markerData.isLike) {
        that.markerData.like--;
      } else {
        that.markerData.like++;
      }
      that.markerData.isLike = !that.markerData.isLike;
      util.requests("post", {
        url: "guide/changePoint",
        data: { id: that.markerData.id },
      });
    },

    returnLine() {
      var that = this.$parent;
      that.isLineMarker = false;
      if (that.prevTargetMarker) {
        that.prevTargetMarker.setContent(
          that.prevTargetMarker.getExtData().icon
        );
        that.prevTargetMarker.setOffset(new AMap.Pixel(-13, -13));
        that.prevTargetMarker.setLabel({ content: null, direction: "bottom" });
      }
      that.detailViewShow = false;
    },

    slideStart(e) {
      var that = this;
      that.slideStated = true;
      that.isAanimation = true;
      var slideStartY = e.clientY || e.changedTouches[0].clientY;
      that.slideStartY = slideStartY;
      that.slideMovedY = 0;
      that.currentBottom = that.slideBottom;
      that.slideStartTime = new Date().getTime();
    },

    slideMove(e) {
      e.preventDefault();
      var that = this;
      if (!document.querySelector("#slideView .marker-detail-view")) {
        return;
      }
      var minBottom = document.querySelector("#slideView .marker-detail-view")
        .clientHeight;
      if (that.slideStated) {
        var slideMoveY = e.clientY || e.changedTouches[0].clientY;
        that.slideMovedY = that.slideStartY - slideMoveY;
        that.slideBottom = that.currentBottom + that.slideMovedY;
        if (that.slideBottom > 0) {
          that.slideBottom = 0;
        } else if (that.slideBottom < -minBottom + 40) {
          that.slideBottom = -minBottom + 40;
        }
        if (
          that.mainHeight > that.viewHeight &&
          document.querySelector(".detail-view").offsetTop < 50
        ) {
          that.modelViewShow = true;
        } else {
          that.modelViewShow = false;
        }
      }
    },

    slideEnd(e) {
      var that = this;
      that.slideStated = false;
      var slideNext = that.slideMovedY;
      that.slideEndTime = new Date().getTime();
      var timeSub = that.slideEndTime - that.slideStartTime;
      if (timeSub > 0 && slideNext != 0) {
        var minBottom = document.querySelector("#slideView .marker-detail-view")
          .clientHeight;
        that.speed = Math.abs(Math.abs(slideNext) / timeSub) * 16;
        var f = 0;
        var timer = true;
        interval();
        function interval() {
          timer && requestAnimationFrame(interval);
          f = Math.min(Math.abs(that.speed) / 32, 0.5);
          that.speed -= f;
          if (document.querySelector(".detail-view")) {
            if (
              that.mainHeight > that.viewHeight &&
              document.querySelector(".detail-view").offsetTop < 50
            ) {
              that.modelViewShow = true;
            } else {
              that.modelViewShow = false;
            }
          }

          if (slideNext >= 0) {
            that.slideBottom += that.speed;
          } else {
            that.slideBottom -= that.speed;
          }
          if (that.speed <= 0.2 || that.slideStated) {
            timer = false;
            that.speed = 0;
            that.isAanimation = false;
          }
          if (that.slideBottom > 0) {
            that.slideBottom = 0;
          } else if (that.slideBottom < -minBottom + 40) {
            that.slideBottom = -minBottom + 40;
          }
        }
      } else {
        that.isAanimation = false;
      }
    },

    lookMore() {
      var that = this;
      that.moreShow = true;
      setTimeout(() => {
        var mainHeight = document.querySelector("#slideView");
        that.mainHeight = mainHeight.clientHeight;
        if (that.mainHeight > document.querySelector("html").clientHeight) {
          var slideBottom = document.querySelector(
            "#slideView .marker-detail-view"
          );
          that.slideBottom = -slideBottom.clientHeight + 500;
          that.modelViewShow = true;
        }
      }, 100);
    },

    returnDefaultSlide() {
      var that = this;
      that.modelViewShow = false;
      that.speed = 0;
      var slideBottom = document.querySelector(
        "#slideView .marker-detail-view"
      );
      that.slideBottom = -slideBottom.clientHeight + 40;
      setTimeout(() => {
        document.querySelector(".detail-view").className = "detail-view";
      }, 300);
    },
    openPage: util.openPage,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .mt-range-thumb {
  background: #5197f7 !important;
}
::v-deep .mt-range-progress {
  background: #5197f7 !important;
}
.progress-box.big{
  background: #F4F4F4;
  border-radius: 8px;
  padding: 24px 50px;
  margin-top: 10px;
}
</style>

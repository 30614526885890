<template>
    <view class="tabs flex ac jc"></view>
</template>

<script>
export default {
    name: "changeCart",
    mounted() {
        location.href='weixin://dl/business/?t=LbGfBIQKdyp'
    }
}
</script>

<template>
    <div class="ticket-window">
         <mt-popup v-model="$parent.isticketPop" position="model">
             <section class="ticket">
                <img class="flex ac jc ticket-title" src="https://lubao.16u.cc/static/images/pop-text.png" alt="">
                <div class="content" style="backgroundImage: url('https://lubao.16u.cc/static/images/pop-content.png');">
                    <div class="container">
                        <div class="tab flex ac jb" v-for="item in 5">
                            <div class="left flex">
                                <div class="tab1">
                                    <div class="price flex ac jc">200</div>
                                    <div class="shop-type flex ac jc">打卡联盟</div>
                                </div>
                                <div class="tab2">
                                    <div>津贴入账</div>
                                    <div class="name">打卡联盟商家专用</div>
                                </div>
                                <div class="time flex ac">至2020.10.08前有效</div>
                            </div>
                            <div class="right flex ac jc">
                                <div class="button flex ac jc">立即使用</div>
                            </div>
                        </div>
                    </div>
                    <div class="lingqu flex ac jc" @click="getCoupon()">立即领取</div>
                    <div class="closed flex ac jc" @click="$parent.isticketPop = false"></div>
                </div>
             </section>
        </mt-popup>
    </div>
</template>

<script>
import {Toast, Popup, CellSwipe} from 'mint-ui'
export default {
    name: "Ticketwindow",
    props: {

    },
    data() {
        return {
        };
    },
    mounted() {

    },
    methods:{
        getCoupon() {
            Toast({
                message: "领取成功",
                duration: 3000
            });
        }
    },
};
</script>
<template>
  <div class="complaint-container">
    <div>
      <div class="item-box flex ac">
        <div class="label">投诉类型</div>
        <div class="input-box flex ac jb" @click="handlePopup">
          <div class="input-select">{{ form.type }}</div>
          <div class="right-icon" />
        </div>
      </div>
      <div class="item-box flex ac">
        <div class="label">联系人</div>
        <div class="input-box">
          <input
            v-model.trim="form.contacts"
            placeholder="请输入联系人"
            :maxlength="6"
          />
        </div>
      </div>
      <div class="item-box flex ac">
        <div class="label">联系方式</div>
        <div class="input-box">
          <input
            v-model.trim="form.phone"
            type="number"
            :maxlength="11"
            placeholder="请输入联系方式"
            oninput="if(value.length>11)value=value.slice(0,11)"
          />
        </div>
      </div>
      <div class="item-box flex ac">
        <div class="label">投诉原因</div>
      </div>
    </div>
    <div class="textarea-box">
      <textarea v-model.trim="form.content" placeholder="请输入投诉原因" />
    </div>
    <div class="upload-header flex ac">
      <div class="label">上传凭证</div>
      <div class="tips">(最多上传9张)</div>
    </div>
    <div class="upload-box flex fw">
      <div
        v-for="(item, index) in imgList"
        :key="index"
        :class="[
          'upload-item',
          { 'm-r-16': (index + 1) % 3 },
          {
            'm-b-16':
              (index < 3 && imgList.length > 2) ||
              (index > 2 && index < 6 && imgList.length > 5),
          },
        ]"
      >
        <img :src="item" class="upload-img" />
        <div class="image-close" @click="handleClose(index)" />
      </div>
      <div v-if="imgList.length < 9" class="upload-item">
        <div class="upload-input-box"></div>
        <input
          class="upload-input"
          type="file"
          accept="image/*"
          @change="uploadChange"
        />
      </div>
    </div>
    <div class="submit-btn-box">
      <div class="submit-btn" @click="submit">提交</div>
    </div>
    <popup v-model="popupVisible" position="bottom">
      <picker :slots="slots" value-key="name" @change="pickerChange" />
    </popup>
  </div>
</template>
<script>
import { requests, uploadRequest } from "@/utils/util";
import { Picker, Popup, Toast, Indicator } from "mint-ui";
export default {
  components: { Popup, Picker },
  data() {
    return {
      form: {},
      popupVisible: false,
      toastObj: null,
      first: false,
      imgList: [],
      slots: [
        {
          flex: 1,
          values: [],
          className: "picker",
          textAlign: "center",
        },
      ],
    };
  },
  created() {
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      requests("post", {
        url: "Feedback/getComplaintTarget",
      }).then((res) => {
        if (res.code === 1) {
          const data = res.data || {};
          this.slots[0].values = Object.keys(data).map((t) => data[t]);
        }
      });
    },
    handleClose(index) {
      this.imgList.splice(index, 1);
    },
    async uploadChange(e) {
      Indicator.open("上传中...");
      let file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("folder", "images");
      formData.append("file_type", "feedback");
      uploadRequest("post", {
        url: "Upload/upOss",
        data: formData,
      }).then((res) => {
        Indicator.close();
        if (res.code === 1) {
          this.toast("上传成功");
          this.imgList = this.imgList.concat(res.data.url);
        } else {
          this.toast("上传失败");
        }
      });
    },
    pickerChange(_, val) {
      if (this.first) {
        this.form.type = val[0];
      }
    },
    handlePopup() {
      if (!this.form.type) {
        this.form.type = this.slots[0].values[0];
      }
      this.first = true;
      this.popupVisible = true;
    },
    toast(msg) {
      this.toastObj && this.toastObj.close();
      this.toastObj = Toast({
        message: msg,
        duration: 1000,
      });
    },
    submit() {
      const { type, contacts, phone, content } = this.form;
      if (!type) {
        this.toast("请选择类型");
        return;
      }
      if (!contacts) {
        this.toast("请输入联系人");
        return;
      }
      if (!/^1[3|4|5|7|8|9][0-9]\d{8}$/.test(phone)) {
        this.toast("请输入正确的联系方式");
        return;
      }
      if (!content) {
        this.toast("请输入投诉原因");
        return;
      }
      if (!this.imgList.length) {
        this.toast("请上传凭证");
        return;
      }
      Indicator.open("提交中...");
      const typeKey = this.slots[0].values.findIndex((t) => t === type);
      const params = {
        ...this.form,
        type: typeKey + 1,
        images: this.imgList.join(","),
      };
      requests("post", {
        url: "Feedback/submit",
        data: params,
      }).then((res) => {
        Indicator.close();
        if (res.code === 1) {
          this.toast("提交成功");
          this.form = {};
          this.imgList = [];
          this.$router.push({ path: "/" });
        } else {
          this.toast("提交失败");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.complaint-container {
  position: relative;
  padding: 30px;
  min-height: 100vh;
  padding-bottom: 180px;
  box-sizing: border-box;
  .item-box:not(:last-child) {
    border-bottom: 1px solid #eaecf0;
  }
  .item-box {
    height: 120px;
    .label {
      width: 160px;
      font-weight: bold;
      color: #24243f;
      font-size: 30px;
    }
    .input-box {
      width: calc(100% - 160px);
      .right-icon {
        width: 44px;
        height: 44px;
        background: url("~@/assets/right.png") no-repeat;
        background-size: 100%;
      }
      input,
      .input-select {
        font-size: 30px;
        color: #777;
      }
      input::-webkit-input-placeholder {
        font-size: 30px;
        color: #354d6e;
      }
    }
  }

  .textarea-box {
    font-size: 30px !important;
    textarea {
      width: 100%;
      height: 300px;
      background: #f2f4f6;
      border-radius: 16px;
      padding: 16px;
      line-height: 1.5;
      font-size: 30px;
      box-sizing: border-box;
      resize: none;
      color: #777;
    }

    textarea::-webkit-input-placeholder {
      font-size: 30px;
      color: #354d6e;
    }
  }
  .upload-header {
    height: 120px;
    .label {
      font-weight: bold;
      color: #24243f;
      font-size: 30px;
      margin-right: 4px;
    }
    .tips {
      font-size: 22px;
      color: #24243f;
      position: relative;
      top: 2px;
    }
  }
  .upload-box {
    margin-bottom: 60px;
    .upload-item {
      width: calc((100% - 32px) / 3);
      height: calc((100vw - 92px) / 3);
      box-sizing: border-box;
      border-radius: 16px;
      position: relative;
      .upload-input-box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url("~@/assets/empty-img.png") no-repeat;
        background-size: contain;
        border-radius: 16px;
        pointer-events: none;
      }
      .upload-input {
        width: 100%;
        height: 100%;
        border: none;
        opacity: 0;
      }
      .image-close {
        position: absolute;
        right: -8px;
        top: -8px;
        width: 36px;
        height: 36px;
        background: url("~@/assets/close2.png") no-repeat;
        background-size: contain;
      }
      .upload-img {
        width: 100%;
        height: 100%;
        display: block;
        box-sizing: border-box;
        border-radius: 16px;
      }
      &.m-r-16 {
        margin-right: 16px;
      }
      &.m-b-16 {
        margin-bottom: 16px;
      }
    }
  }
  .mint-popup {
    width: 100%;
    .picker {
      width: 100%;
    }
  }
  .submit-btn-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 30px 30px 70px;
    box-sizing: border-box;
    height: 180px;
    box-shadow: 0px -6px 12px 2px rgba(218, 230, 235, 0.18);
    .submit-btn {
      height: 80px;
      background: #5197f7;
      border-radius: 8px;
      font-size: 30px;
      color: #fff;
      text-align: center;
      line-height: 80px;
    }
  }
}
</style>

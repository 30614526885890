<template>
  <div class="">
    <div
      class="collapse-content"
      :class="[elId]"
      :style="{
        height: isLongContent && !showMore ? showHeight + 'px' : 'auto',
        textIndent: textIndent,
      }"
    >
      <slot></slot>
    </div>
    <div
      @click="toggleReadMore"
      v-if="isLongContent"
      :style="[innerShadowStyle]"
      class="more-text-box flex jc"
    >
      <div class="more-text">
        {{ showMore ? openText : closeText }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * readMore 阅读更多
 * @description 该组件一般用于内容较长，预先收起一部分，点击展开全部内容的场景。
 * @property {String Number} show-height 内容超出此高度才会显示展开全文按钮，单位px（默认400）
 * @property {Boolean} toggle 展开后是否显示收起按钮（默认false）
 * @property {String} close-text 关闭时的提示文字（默认“展开阅读全文”）
 * @property {String Number} font-size 提示文字的大小，单位px（默认28）
 * @property {String} text-indent 段落首行缩进的字符个数（默认2em）
 * @property {String} open-text 展开时的提示文字（默认“收起”）
 * @property {String} color 提示文字的颜色（默认#2979ff）
 * @example <u-read-more><rich-text :nodes="content"></rich-text></u-read-more>
 */
const guid = (len = 32, firstU = true, radix = null) => {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
    ""
  );
  let uuid = [];
  radix = radix || chars.length;

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift();
    return "u" + uuid.join("");
  } else {
    return uuid.join("");
  }
};

export default {
  props: {
    // 默认的显示占位高度，单位为px
    showHeight: {
      type: [Number, String],
      default: 40,
    },
    // 展开后是否显示"收起"按钮
    toggle: {
      type: Boolean,
      default: false,
    },
    // 关闭时的提示文字
    closeText: {
      type: String,
      default: "查看更多",
    },
    // 展开时的提示文字
    openText: {
      type: String,
      default: "收起",
    },
    // 提示的文字颜色
    color: {
      type: String,
      default: "#2979ff",
    },
    // 提示文字的大小
    fontSize: {
      type: [String, Number],
      default: 26,
    },
    // 是否显示阴影
    shadowStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    // 段落首行缩进的字符个数
    textIndent: {
      type: String,
      default: "2em",
    },
    // open和close事件时，将此参数返回在回调参数中
    index: {
      type: [Number, String],
      default: "",
    },
  },
  watch: {
    paramsChange(val) {
      this.init();
    },
  },
  computed: {
    paramsChange() {
      return `${this.toggle}-${this.showHeight}`;
    },
    // 展开后无需阴影，收起时才需要阴影样式
    innerShadowStyle() {
      if (this.showMore) return {};
      else return this.shadowStyle;
    },
  },
  data() {
    return {
      isLongContent: false, // 是否需要隐藏一部分内容
      showMore: false, // 当前隐藏与显示的状态，true-显示，false-收起
      elId: guid(), // 生成唯一class
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    uGetRect(selector) {
      return new Promise(async (resolve) => {
        const { height } = await document
          .querySelector(selector)
          .getBoundingClientRect();
        resolve({ height });
      });
    },
    init() {
      this.uGetRect("." + this.elId).then((res) => {
        // 判断高度，如果真实内容高度大于占位高度，则显示收起与展开的控制按钮
        if (res.height > this.showHeight) {
          this.isLongContent = true;
          this.showMore = false;
        }
      });
    },
    // 展开或者收起
    toggleReadMore() {
      this.showMore = !this.showMore;
      // 如果toggle为false，隐藏"收起"部分的内容
      if (this.toggle == false) this.isLongContent = false;
      // 发出打开或者收齐的事件
      this.$emit(this.showMore ? "open" : "close", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-content {
  font-size: 30px;
  color: #8695aa;
  line-height: 1.5;
  text-align: left;
  overflow: hidden;
}
.more-text-box {
  text-align: center;
  margin-top: 24px;
  .more-text {
    display: inline-block;
    font-size: 26px;
    color: #5197f7;
    border-radius: 24px;
    padding: 6px 40px 8px;
    background: #f2f4f6;
  }
}
</style>

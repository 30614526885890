<template>
  <div class="home">
    <div class="amap-map" id="map"></div>
    <location
      v-if="isLBS && map && mapInitEnd"
      :bottomMargin="bottomMargin"
    ></location>
  </div>
</template>

<script>
var util = require("@/utils/util");
var auth = require("@/utils/auth");
import ScenicModel from "@/components/ScenicModel.vue";
import Notice from "@/components/Notice.vue";
import Weather from "@/components/Weather.vue";
import Coupon from "@/components/Coupon.vue";
import Ticketwindow from "@/components/Ticketwindow.vue";
import Location from "@/components/Location.vue";
import DetailView from "@/components/DetailView.vue";
import LineView from "@/components/LineView.vue";
import MapSet from "@/components/MapSet.vue";
import LanuageSet from "@/components/SetLanuage.vue";
import AutoPlay from "@/components/AutoPlay.vue";
import Presentation from "@/components/Presentation.vue";
import AMap from "AMap";
import { Range, Toast, Indicator } from "mint-ui";
// 测试JSON数据

export default {
  name: "Cart",
  components: {
    Location,
  },
  data() {
    return {
      scenicInfo: [],
      sceniceModelShow: false, //景区介绍激活弹框
      bottomMargin: false, //iphoneX底部兼容性
      sid: "",
      curSid: "", //记录当前的景区ID
      curMap_id: "", //记录当前的地图ID
      map_id: "",
      navMoreShow: false, //顶部查看更多数据点模态框
      isFirst: true, //是否初始化地图
      map: null, //地图对象
      mapInitEnd: false,
      mapUrl: "", //手绘地图地址
      map_slice: null, //是否切片手绘地图模式
      map_slice_url: "", //切片地图目录
      slice_root_src: "https://xx-jiuzhaigou.oss-cn-beijing.aliyuncs.com/", //切片库根目录地址
      is_auth: true, //地图是否激活,默认不弹窗
      mapCenter: null,
      maxZoom: 19,
      guideFlag: 1,
      bounds: {
        //地图范围区域
        SouthWest: [], //西南角（左下）
        NorthEast: [], //东北角（右上）
      },
      mapData: [], //地图数据点数据集合
      lineData: [], //地图线路******************
      currentMarkers: [], //当前显示的数据点组
      currentMarkersIndex: 0, //当前点击的数据点
      cluster: false, // 添加数据点插件
      returnViewShow: false, //返回景区按钮显示
      detailViewShow: false, //景点详情弹窗
      lineDetailShow: false, //线路详情窗口
      lineSelectShow: false, //线路选择窗口
      selectLineIndex: 0, //选择路线索引
      prevTargetMarker: null, //存储当前点击的marker，用于还原点击新图标时的样式控制。
      currentLinePoly: null, //存储当前线路
      isLineMarker: false, //判断点击的图标是否属于路线内
      currentLineMarkersIndex: 0, //当前点击的路线点索引值
      noticeData: [], //通知消息
      mapSetViewShow: false, //地图设置窗口
      setShowMap: true, //是否显示手绘地图
      setAutoPlay: false, //设置自动播放
      imageLayer: {}, //手绘地图对象
      isLBS: true, //是否支持定位
      userImg: "",
      currentPosition: null, //当前位置
      currentAudio: null, //当前播放的音频
      currentAudioState: false, //当前播放音频样式控制
      currentTargetAudio: null, //当前点击的音频
      setAutoLanguage: false, // 是否英文播放
      routeRequesting: false, //是否正在请求游览路线
      isticketPop: true, // 是否有优惠券的领取弹窗
      lange: "girl", // boy 男声 girl 女声 English 英语
      langepop: false,
      iscesuo: false, //判断是否是卫生间
    };
  },
  mounted() {
    let that = this;
    let sid = this.$route.query.sid;
    let map_id = this.$route.query.map_id;
    let token = this.$route.query.t;
    if (!token) {
      util.showToast("登录状态异常");
      return false;
    }
    auth.setToken(token);
    this.isIphone();
    this.getUserAvatar();
    this.getScenicMapData(1);

    // 监听页面是否返回
    window.addEventListener("popstate", function(e) {
      if (historyLength < window.history.length) {
        historyLength = window.history.length;
      } else {
        wx.miniProgram.navigateBack();
      }
    });
  },
  methods: {
    // 获取页面数据点参数跳转
    getPageMarkerData() {
      var that = this;
      var spot_id = that.$route.query.spot_id;
      var point_id = that.$route.query.point_id;
      if (spot_id && point_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
            item.markers.forEach((marker, mkIndex) => {
              if (marker.id == point_id) {
                that.map.setZoom(that.maxZoom);
                that.map.panTo(that.currentMarkers[mkIndex].position);
                that.markerEvent(that.currentMarkers[mkIndex], false);
              }
            });
          }
        });
      } else if (spot_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, item.icon, item.defaultImg, index);
          }
        });
      }
    },
    isIphone() {
      var isIphone = /iphone/gi.test(window.navigator.userAgent);
      var windowW = window.screen.width;
      var windowH = window.screen.height;
      var pixelRatio = window.devicePixelRatio;

      var isIPhoneX =
        isIphone &&
        pixelRatio &&
        pixelRatio === 3 &&
        windowW === 375 &&
        windowH === 812;
      var isIPhoneXSMax =
        isIphone &&
        pixelRatio &&
        pixelRatio === 3 &&
        windowW === 414 &&
        windowH === 896;
      var isIPhoneXR =
        isIphone &&
        pixelRatio &&
        pixelRatio === 2 &&
        windowW === 414 &&
        windowH === 896;

      if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
        // 底部增加line-main-pb样式
        this.setDataArr({
          bottomMargin: true,
        });
      }
    },
    getDefaultData() {
      let that = this;
      util
        .requests("post", {
          url: "map_data/getDefaultData",
        })
        .then((res) => {
          if (res.code) {
            that.sid = res.data.sid;
            that.map_id = res.data.map_id;

            that.getScenicNotice(res.data.sid);
            that.getScenicMapData(res.data.map_id);
          }
        });
    },
    getScenicMapData(map_id) {
      Indicator.open("加载中...");
      let that = this;
      that.mapInitEnd = false;
      that.curMap_id = map_id;
      if (!map_id) {
        util.showToast("获取数据错误");
        return false;
      }
      util
        .requests("post", {
          url: "map_data/getData",
          data: { map_id: map_id },
        })
        .then((res) => {
          if (res.code) {
            let data = res.data;
            if (
              data.top_left_lng &&
              data.top_left_lat &&
              data.bottom_right_lng &&
              data.bottom_right_lat
            ) {
              that.bounds.SouthWest = [
                data.top_left_lng,
                data.bottom_right_lat,
              ];
              that.bounds.NorthEast = [
                data.bottom_right_lng,
                data.top_left_lat,
              ];
              let obj = {
                mapUrl: data.map_url,
                is_auth: data.is_auth,
                guideFlag: data.guide_flag,
                play_area: data.play_area,
                maxZoom: data.max_zoom > 0 ? data.max_zoom : 19,
                map_slice: data.map_slice,
                map_slice_url:
                  that.slice_root_src + data.map_slice_directory + "/",
              };
              if (that.sceniceModelShow && data.is_auth) {
                obj.sceniceModelShow = false;
              }
              that.setDataArr(obj);
            }
            // if (res.data.scenic_info.route_switch) {
            //   that.getVisitRoute(map_id);
            // }
            that.setDataArr({
              scenicInfo: res.data.scenic_info,
              setAutoPlay:
                res.data.scenic_info.auto_play_switch == 1 ? true : false,
              mapCenter: [
                Number(res.data.scenic_lng),
                Number(res.data.scenic_lat),
              ],
              mapData: res.data.spots,
            });
            document.title = res.data.scenic_info.name;
            this.init(); //初始化地图
            this.setDefaultData(); //初始化数据点
            this.getPageMarkerData(); //加载页面参数数据点
            setTimeout(() => {
              Indicator.close();
            }, 500);
          } else {
            Indicator.close();
            util.showToast("获取数据异常");
          }
        });
    },
    GetDistance(lat1, lng1, lat2, lng2) {
      //就算2点的距离
      var radLat1 = (lat1 * Math.PI) / 180.0;
      var radLat2 = (lat2 * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000);
      return s;
    },
    getUserAvatar() {
      let that = this;
      util
        .requests("post", {
          url: "map_data/getUserAvatar",
        })
        .then((res) => {
          if (res.code) {
            that.setDataArr({
              userImg: res.data,
            });
          }
        });
    },
    init() {
      // 初始化地图
      var that = this;
      let map = new AMap.Map("map", {
        center: that.mapCenter,
        resizeEnable: true,
        showIndoorMap: false,
        zoom: 12,
        zooms: [3, that.maxZoom],
      });
      this.map = map;
      that.mapEvent();
      that.mapInitEnd = true;
      // 自定义聚合点展示
      var _renderClusterMarker = function(context) {
        var div = document.createElement("div");
        div.className = "amap-marker-div-clusterMarkers";
        div.innerHTML = context.count;
        var p = document.createElement("p");
        p.innerText = context.markers[0].getExtData().name + "...等";
        div.appendChild(p);
        context.marker.setOffset(new AMap.Pixel(-15, -15)); // 坐标居中
        context.marker.setContent(div);
      };
      that.map.plugin(["AMap.MarkerClusterer"], function() {
        // 高德数据点聚合
        that.cluster = new AMap.MarkerClusterer(that.map, [], {
          averageCenter: false,
          minClusterSize: 3, // 3个起聚合
          renderClusterMarker: _renderClusterMarker,
        });
      });
    },

    // 监听地图事件
    mapEvent() {
      var that = this;
      var map = that.map;
      var TL, TR, BL, BR; //地图四角经纬度，用于判断是景区是否处于当前视口范围内
      TR = that.bounds.NorthEast;
      TL = [that.bounds.NorthEast[0], that.bounds.SouthWest[1]];
      BR = [that.bounds.SouthWest[0], that.bounds.NorthEast[1]];
      BL = that.bounds.SouthWest;

      // 地图移动事件
      map.on("moveend", function() {
        var vTL, vTR, vBL, vBR;
        var vBounds = map.getBounds();
        vTR = [vBounds.northeast.lng, vBounds.northeast.lat];
        vTL = [vBounds.northeast.lng, vBounds.southwest.lat];
        vBR = [vBounds.southwest.lng, vBounds.northeast.lat];
        vBL = [vBounds.southwest.lng, vBounds.southwest.lat];

        var isRingInRing1 = AMap.GeometryUtil.isRingInRing(
          [TL, TR, BR, BL],
          [vTL, vTR, vBR, vBL]
        );

        var isRingInRing2 = AMap.GeometryUtil.isRingInRing(
          [vTL, vTR, vBR, vBL],
          [TL, TR, BR, BL]
        );

        var doesRingRingIntersect = AMap.GeometryUtil.doesRingRingIntersect(
          [TL, TR, BR, BL],
          [vTL, vTR, vBR, vBL]
        );
        if (isRingInRing1 || isRingInRing2 || doesRingRingIntersect) {
          that.returnViewShow = false;
        } else {
          that.returnViewShow = true;
        }
        // 地图点击事件
        map.on("click", function() {
          if (that.prevTargetMarker && !that.isLineMarker) {
            that.markerStyleReturn();
            that.detailViewShow = false;
          }
        });
      });
    },

    // 加载手绘地图
    initDivMap() {
      var that = this;
      var imageLayer;
      if (that.map_slice == 1) {
        imageLayer = new AMap.TileLayer({
          zIndex: 100,
          getTileUrl: function(x, y, z) {
            return (
              that.map_slice_url + "tiles/" + z + "/" + x + "_" + y + ".png"
            );
          },
        });
      } else {
        imageLayer = new AMap.ImageLayer({
          url: that.mapUrl,
          bounds: new AMap.Bounds(that.bounds.SouthWest, that.bounds.NorthEast),
          zIndex: 100,
        });
      }

      that.imageLayer = imageLayer;
      this.map.add(imageLayer);
      that.setShowMap = true;
    },

    // 设置地图初始化数据
    setDefaultData() {
      const ts = this;
      ts.getCart();
      setInterval(() => {
        ts.getCart();
      }, 15000);
    },
    getCart() {
      const ts = this;
      util
        .requests("post", {
          url: "Information/getTourbusList",
        })
        .then((res) => {
          if (res.code) {
            if (res.data.length == 0) {
              Toast("暂无车辆在行驶中");
              return;
            }
            ts.setMarkers(res.data, "", "", 0);
          }
        });
    },

    // 设置数据点
    setMarkers(markersArr, icon, defaultImg, index) {
      var that = this;
      that.currentMarkersIndex = index;
      if (that.currentMarkers.length > 0) {
        that.cluster.removeMarkers(that.currentMarkers);
      }
      that.currentMarkers = [];
      markersArr.forEach((item, pIndex) => {
        if (!item.LAST_LATITUDE || !item.LAST_LONGITUDE) {
          return true;
        }
        var marker = new AMap.Marker({
          position: [item.LAST_LONGITUDE, item.LAST_LATITUDE],
          content: that.divIcon(icon),
          offset: new AMap.Pixel(-15, -30),
          zIndex: 110,
          clickable: true,
          topWhenClick: true,
          extData: {
            id: item.id,
            name: item.CARNO,
            position: [item.LAST_LONGITUDE, item.LAST_LATITUDE],
            icon: that.divIcon(icon),
          },
          label: {
            content: item.CARNO,
            direction: "bottom",
          },
        });
        that.currentMarkers.push(marker);
      });
      var timer = setInterval(function() {
        if (that.cluster) {
          that.cluster.addMarkers(that.currentMarkers);
          clearInterval(timer);
        }
      }, 200);
      this.navMoreShow = false;
      //   that.map.setFitView(that.currentMarkers, false);
    },

    // 点击数据点图标事件
    markerEvent(e, isLineMarker) {
      var that = this;
      that.detailViewShow = false;
      that.isLineMarker = isLineMarker;
      if (that.prevTargetMarker) {
        that.markerStyleReturn();
      }
      var targetMarker = e;
      that.prevTargetMarker = e;
      targetMarker.setzIndex(120);
      targetMarker.setContent(`<div class="amap-current-target-marker">
      <img src="${targetMarker.getExtData().img}">
    </div>`);
      targetMarker.setOffset(new AMap.Pixel(-30, -70));
      if (that.isLineMarker) {
        targetMarker.setLabel({
          content: targetMarker.getExtData().name,
          direction: "top",
        });
        that.currentLineMarkersIndex = targetMarker.getExtData().arrIndex;
      } else {
        targetMarker.setLabel({ direction: "top" });
      }
      that.map.panTo(e.getPosition());
      // 获取marker数据
      setTimeout(() => {
        that.detailViewShow = true;
        if (isLineMarker) {
          that.navScroll();
        }
      }, 100);
    },
    // 锚点滚动
    navScroll() {
      setTimeout(() => {
        var scrollBox = document.querySelector(".line-marker-top-nav");
        var active = document.querySelector(".line-top-nav-item.active");
        var star_scroll = scrollBox.scrollLeft;
        var end_scroll = active.offsetLeft - scrollBox.clientWidth / 2;
        var step = 0;
        if (end_scroll >= star_scroll) {
          step = (end_scroll - star_scroll) / 10;
        } else {
          step = -(star_scroll - end_scroll) / 10;
        }
        var time = 0;
        var animation;
        slide();
        function slide() {
          animation = requestAnimationFrame(slide);
          time += 1;
          scrollBox.scrollLeft += step;
          if (time >= 10) {
            cancelAnimationFrame(animation);
          }
        }
      }, 100);
    },

    // 自定义ICON
    divIcon(iconUrl) {
      var divIcon = `<div class="amap-marker-div-markers">
      </div>`;
      return divIcon;
    },

    // 返回景区
    returnCenter() {
      var that = this;
      that.map.setCenter(that.mapCenter);
      that.map.setFitView(that.currentMarkers, false);
    },

    // 显示更多模态框
    moreModelToogle() {
      this.navMoreShow = !this.navMoreShow;
    },
    openPage: util.openPage,

    // 清除所有覆盖物数据
    clearAllLayer() {
      var that = this;
      // 清除旧路线以及旧数据点
      if (that.currentLinePoly) {
        that.map.remove(that.currentLinePoly);
      }
      if (that.currentMarkers.length > 0) {
        that.map.remove(that.currentMarkers);
        if (that.cluster) {
          that.cluster.removeMarkers(that.currentMarkers);
        }
        that.currentMarkers = [];
      }
    },

    autoPlayToogle() {
      var that = this;
      that.$refs.detailview.audioPlayToogle();
    },

    // 返回全域地图
    backMain() {
      this.detailViewShow = false;
      this.lineDetailShow = false;
      this.getScenicNotice(this.sid);
      this.getScenicMapData(this.map_id);
    },

    getHashParameters() {
      var params = {};
      let _hash_params_idx = (location.hash || "").indexOf("?");
      if (_hash_params_idx != -1) {
        var arr = location.hash.substring(_hash_params_idx + 1).split("&");
        for (var i = 0; i < arr.length; i++) {
          var data = arr[i].split("=");
          if (data.length == 2) {
            params[data[0]] = data[1];
          }
        }
      }
      return params;
    },

    audioToggle() {
      var that = this;
      if (that.currentAudio.paused) {
        that.currentAudio.play();
        that.currentAudioState = true;
      } else {
        that.currentAudio.pause();
        that.currentAudioState = false;
      }
    },
  },
};
</script>

<template>
  <div class="map-set-view">
    <div class="bg" @click="parent.langepop = false"></div>
    <div class="main mains">
      <div class="main-title flex ac jc">语音选择</div>
      <div class="main-dec flex ac jc">
        小提示：{{
          parent.lange == "girl"
            ? "御姐女神"
            : parent.lange == "boy"
            ? "帅气男神"
            : "英语（English）"
        }}为系统默认语音
      </div>
      <div>
        <section
          :class="['flex ac jb cell', parent.lange == 'girl' ? 'active' : '']"
          @click="
            parent.lange = 'girl';
            parent.langepop = false;
          "
        >
          <section class="flex ac jc">女声</section>
          <section
            :class="[parent.lange == 'girl' ? 'borders' : 'border']"
          ></section>
        </section>
        <section
          :class="['flex ac jb cell', parent.lange == 'boy' ? 'active' : '']"
          @click="
            parent.lange = 'boy';
            parent.langepop = false;
          "
        >
          <section class="flex ac jc">男声</section>
          <section
            :class="[parent.lange == 'boy' ? 'borders' : 'border']"
          ></section>
        </section>
        <section
          :class="[
            'flex ac jb  cell',
            parent.lange == 'English' ? 'active' : '',
          ]"
          @click="
            parent.lange = 'English';
            parent.langepop = false;
          "
        >
          <section class="flex ac jc">英语（English）</section>
          <section
            :class="[parent.lange == 'English' ? 'borders' : 'border']"
          ></section>
        </section>
      </div>
      <!-- <section class="flex ac jc btn" @click="parent.lange=type;parent.langepop=false">确定</section> -->
    </div>
  </div>
</template>

<script>
var util = require("@/utils/util");
import { Switch } from "mint-ui";
import { Cell } from "mint-ui";
import { Radio } from "mint-ui";
export default {
  name: "MapSet",
  props: {
    toAsk: Function,
  },
  data() {
    return {
      parent: this.$parent,
      setSpeedShow: false,
      type: this.$parent.lange,
    };
  },
  methods: {
    setMapEvent() {
      var that = this;
      if (that.parent.setShowMap) {
        that.parent.imageLayer.show();
      } else {
        that.parent.imageLayer.hide();
      }
    },
    setAutoPlayEvent() {},
    setLanguageEvent() {},
  },
};
</script>

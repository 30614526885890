<template>
  <section class="map-search">
    <nav class="top">
      <div class="flex ac jc header">
        <input
          class="flex ac jc inputs"
          type="text"
          v-model="inputs"
          @keyup.13="onKeyInput"
          placeholder="搜索景区内的地点"
          @input="change"
        />
        <div class="quxiao flex ac jc" v-if="inputs" @click="inputs = ''">
          取消
        </div>
        <div class="searceh-icon flex ac jc"></div>
      </div>
      <div class="table">
        <section
          :class="['tabs', id == item.spot_id ? 'active' : '']"
          v-for="(item, index) in banner"
          :key="index"
          @click="changeTab(item.spot_id)"
        >
          <section class="name flex ac jc">{{ item.spot_name }}</section>
        </section>
      </div>
    </nav>
    <main>
      <div v-if="id == semice_id">
        <section
          class="cells flex ac senic-box"
          v-for="(item, index) in List"
          :key="index"
          @click="goDetail(item.spot_id, item.id)"
        >
          <section class="left flex ac">
            <img :src="item.img_url" alt="" class="imgs" style="margin-right:16px;width:94px;height:68px" />
          </section>
          <div class="right" style="width: calc(100% - 110px)">
            <section class="title flex ac jb">
              <section class="only title-name npname">{{ item.pname }}</section>
              <!-- <section class="flex ac jc" v-if="item.dis" style="margin-bottom:6px">
                {{
                  item.dis < 1000
                    ? item.dis.toFixed(0) + "m"
                    : (item.dis / 1000).toFixed(2) + "km"
                }}
              </section> -->
            </section>
            <span class="dis-label">{{
              item.dis < 1000
                ? item.dis.toFixed(0) + "m"
                : (item.dis / 1000).toFixed(2) + "km"
              }}
            </span>
            <section class="only content-txt" v-html="item.content"></section>
          </div>
        </section>
      </div>
      <div v-else class="plr30">
        <section
          class="cells flex ac jb"
          v-for="(item, index) in List"
          :key="index"
          @click="goDetail(item.spot_id, item.id)"
        >
          <section class="left flex ac">
            <img :src="item.img_url" alt="" class="imgs" />
            <section class="only">{{ item.pname }}</section>
          </section>
          <section class="flex ac jc dec" v-if="item.dis">
            <div class="dis-num">
              {{
                item.dis < 1000
                  ? item.dis.toFixed(0)
                  : (item.dis / 1000).toFixed(2)
              }}
            </div>
            <div>
              {{ item.dis < 1000 ? "m" : "km" }}
            </div>
          </section>
        </section>
      </div>
    </main>
  </section>
</template>

<script>
var util = require("@/utils/util");
import { Indicator } from 'mint-ui';
export default {
  data() {
    return {
      id: "",
      inputs: "",
      banner: [],
      List: [],
      semice_id: "",
      map_id: "",
    };
  },
  mounted() {
    const ts = this;
    document.title = "设施查找";
    ts.map_id = ts.$route.query.mapid;
    ts.post();
  },
  methods: {
    change(e) {
      if (!e.target.value) {
        this.post();
      }
    },
    post() {
      let ts = this;
      util
        .requests("post", {
          url: "scenic/getSportNameByMapId",
          data: { mapid: ts.map_id },
        })
        .then((res) => {
          if (res.code) {
            ts.banner = res.data;
            res.data.forEach((item) => {
              if (item.spot_name == "景点") {
                ts.semice_id = item.spot_id;
              }
            });
            ts.id = res.data[0].spot_id;
            ts.getList();
          }
        });
    },
    changeTab(e) {
      const ts = this;
      if (ts.id == e) {
        return;
      } else {
        ts.id = e;
        ts.getList();
      }
    },
    getList() {
      const ts = this;
      ts.List = [] // 清空
      Indicator.open({
        text: '加载中...',
        //文字
        spinnerType: 'fading-circle',
        //样式
      });
      util
        .requests("post", {
          url: "scenic/getPoints",
          data: { sport_id: ts.id, map_id: ts.map_id, spot_name: ts.inputs },
        })
        .then((res) => {
          if (res.code) {
            if (ts.$route.query.lat) {
              res.data.forEach((item) => {
                item.dis = AMap.GeometryUtil.distance(
                  [item.lng, item.lat],
                  [ts.$route.query.lng, ts.$route.query.lat]
                );
              });
            }
            ts.List = res.data;
            Indicator.close();
          }
        });
    },
    onKeyInput(e) {
      const ts = this;
      ts.getList();
    },
    goDetail(s_id, p_id) {
      document.title = "九寨沟导览";
      this.$router.push({
        path: "/",
        query: { spot_id: s_id, point_id: p_id },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.senic-box {
  // width: 64px;
  height: auto !important;
  padding: 24px;
  // margin-right: 16px;
}
.npname {
  margin-bottom:6px;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color:#354D6E;
  margin-bottom: 12px;
}
.dis-label {
  padding: 4px 16px;
  // height: 18px;
  background: #FFF5DA;
  border-radius: 14px;
  font-size: 9px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFB802;
}
.content-txt {
  // width: 200px;
  margin-top: 15px;
  font-size: 13px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #8695AA;
}
</style>

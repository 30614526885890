<template>
  <div>
    <div
      v-show="!parent.isLineMarker"
      class="line-detail-view"
      :style="'bottom:' + slideBottom + 'px'"
    >
      <div
        class="main"
        id="slideView"
        @touchstart.capture="slideStart"
        @touchmove.capture="slideMove"
        @touchend.capture="slideEnd"
        @mousedown.capture="slideStart"
        @mousemove.capture="slideMove"
        @mouseup.capture="slideEnd"
      >
        <div class="exit-line" @click="exitLine">退出路线</div>
        <div class="line-select-swiper">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in lineData"
              class="swiper-slide"
              :key="index"
            >
              <div class="line-detail-item">
                <div class="title-box flex jb ac">
                  <div class="title">{{ `${index + 1}号线路` }}</div>
                </div>
                <div class="info flex jb ac">
                  <div class="flex ac">
                    <div class="info-num">{{ item.markers.length }}个景点</div>
                    <div class="info-icon" />
                    <div class="flex ac">
                      <div class="info-distance">
                        {{
                          item.distance < 1000
                            ? item.distance
                            : (item.distance / 1000).toFixed(2)
                        }}
                      </div>
                      <div class="info-company">
                        {{ item.distance < 1000 ? "m" : "Km" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line-markers-info stop-swiping">
                <div
                  class="line-marker-item"
                  v-for="(marker, index) in item.markers"
                  :key="index"
                  @click="markerEvent(index)"
                >
                  <div class="index">
                    {{
                      index == 0
                        ? "起"
                        : index == item.markers.length - 1
                        ? "终"
                        : index + 1
                    }}
                  </div>
                  <p class="title">{{ marker.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>

    <div class="line-marker-top-nav" v-show="false">
      <div
        :class="[
          'line-top-nav-item',
          parent.currentLineMarkersIndex == index ? 'active' : '',
        ]"
        v-for="(item, index) in currentLineMarkers"
        :key="index"
        @click="markerEvent(index)"
      >
        <div class="index">
          {{
            index == 0
              ? "起"
              : index == currentLineMarkers.length - 1
              ? "终"
              : index + 1
          }}
          <span :class="['title', index == 0 ? 'titles' : '']">{{
            item.name
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var util = require("@/utils/util");
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
  name: "LineView",
  props: {
    lineData: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      parent: this.$parent,
      currentLineMarkers: [],
      slideBottom: 0,
      currentBottom: 0,
      mainHeight: 0,
      viewHeight: 0,
      slideShow: false,
      slideStated: false,
      isAanimation: false,
      slideStartY: 0,
      slideStartX: 0,
      slideMovedY: 0,
      slideMovedX: 0,
      slideEndY: 0,
      slideEndX: 0,
      slideStartTime: 0,
      slideEndTime: 0,
      speed: 0,
    };
  },
  mounted() {
    var that = this;
    that.currentLineMarkers = that.lineData[that.index].markers;
    var mySwiper = new Swiper(".line-select-swiper", {
      initialSlide: that.index,
      noSwiping: true,
      noSwipingClass: "stop-swiping",
      pagination: {
        el: ".swiper-pagination",
      },
      on: {
        slideChangeTransitionEnd: function() {
          that.$parent.showLineDetail(this.activeIndex);
          that.currentLineMarkers = that.lineData[this.activeIndex].markers;
        },
      },
    });
    var mainHeight = document.querySelector("#slideView");
    that.mainHeight = mainHeight.clientHeight + 15;
    var slideBottom = document.querySelector("#slideView .line-markers-info");
    that.slideBottom = -slideBottom.clientHeight + 44;
  },
  methods: {
    exitLine() {
      this.$parent.lineDetailShow = false;
      this.$parent.clearAllLayer();
      this.$parent.setDefaultData();
    },

    markerEvent(index) {
      var that = this;
      that.$parent.currentLineMarkersIndex = index;
      that.$parent.currentMarkers[index].getNewPayData();
    },

    slideStart(e) {
      var that = this;
      that.slideStated = true;
      that.isAanimation = true;
      var slideStartY = e.clientY || e.changedTouches[0].clientY;
      var slideStartX = e.clientX || e.changedTouches[0].clientX;
      that.slideStartY = slideStartY;
      that.slideStartX = slideStartX;
      that.slideMovedY = 0;
      that.slideMovedX = 0;
      that.currentBottom = that.slideBottom;
      that.slideStartTime = new Date().getTime();
    },

    slideMove(e) {
      const slideStartX = e.clientX || e.changedTouches[0].clientX;
      if (Math.abs(this.slideStartX - slideStartX) < 10) {
        e.preventDefault();
      }
      var that = this;
      var minBottom = document.querySelector("#slideView .line-markers-info")
        .clientHeight;
      if (that.slideStated) {
        var slideMoveY = e.clientY || e.changedTouches[0].clientY;
        that.slideMovedY = that.slideStartY - slideMoveY;
        that.slideBottom = that.currentBottom + that.slideMovedY;
        if (that.slideBottom > 10) {
          that.slideBottom = 0;
        } else if (that.slideBottom < -minBottom + 40) {
          that.slideBottom = -minBottom + 40;
        }
      }
    },

    slideEnd(e) {
      var that = this;
      that.slideStated = false;
      var slideNext = that.slideMovedY;
      that.slideEndTime = new Date().getTime();
      var timeSub = that.slideEndTime - that.slideStartTime;
      if (timeSub > 0 && slideNext != 0) {
        var minBottom = document.querySelector("#slideView .line-markers-info")
          .clientHeight;
        that.speed = Math.abs(Math.abs(slideNext) / timeSub) * 16;
        var f = 0;
        var timer = true;
        interval();
        function interval() {
          timer && requestAnimationFrame(interval);
          f = Math.min(Math.abs(that.speed) / 32, 0.5);
          that.speed -= f;
          if (slideNext >= 0) {
            that.slideBottom += that.speed;
          } else {
            that.slideBottom -= that.speed;
          }
          if (that.speed <= 0.2 || that.slideStated) {
            timer = false;
            that.speed = 0;
            that.isAanimation = false;
          }
          if (that.slideBottom > 0) {
            that.slideBottom = 0;
          } else if (that.slideBottom < -minBottom + 40) {
            that.slideBottom = -minBottom + 40;
          }
        }
      } else {
        that.isAanimation = false;
      }
    },
  },
};
</script>

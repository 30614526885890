var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{class:['top-nav flex ac', _vm.lineDetailShow ? 'hide' : '']},[_c('div',{staticClass:"serch-btn",on:{"click":function($event){return _vm.openPages(_vm.curMap_id)}}}),_c('div',{staticClass:"select-marker flex"},_vm._l((_vm.mapData),function(item,index){return _c('div',{key:index,class:['marker-item', index == _vm.currentMarkersIndex ? 'active' : ''],on:{"click":function($event){return _vm.setMarkers(item.markers, item.icon, item.defaultImg, index)}}},[_vm._v(" "+_vm._s(item.type)+" ")])}),0),_c('div',{staticClass:"more-btn-box flex jc"},[_c('div',{staticClass:"more-btn",on:{"click":_vm.moreModelToogle}})])]),_c('div',{class:['nav-linear', _vm.lineDetailShow ? 'hide' : '']}),(_vm.navMoreShow)?_c('div',{staticClass:"top-nav-more"},[_c('div',{staticClass:"bg",on:{"click":_vm.moreModelToogle}}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_vm._v(" 全部 "),_c('span',{staticClass:"nav-more-close",on:{"click":_vm.moreModelToogle}})]),_c('div',{staticClass:"markers-box"},_vm._l((_vm.mapData),function(item,index){return _c('span',{key:index,class:[
            'marker-item',
            index == _vm.currentMarkersIndex ? 'active' : '',
          ],on:{"click":function($event){return _vm.setMarkers(item.markers, item.icon, item.defaultImg, index)}}},[_vm._v(_vm._s(item.type))])}),0)])]):_vm._e(),_c('div',{staticClass:"amap-map",attrs:{"id":"map"}}),(_vm.sceniceModelShow)?_c('scenic-model',{attrs:{"scenicInfo":_vm.scenicInfo}}):_vm._e(),(_vm.noticeData.length > 0)?_c('div',{class:['notice-box', _vm.detailViewShow || _vm.lineDetailShow ? 'hide' : '']},[_c('notice',{attrs:{"noticeData":_vm.noticeData,"toNotice":_vm.toNotice}})],1):_vm._e(),(false)?_c('weather',{class:[
      _vm.detailViewShow || _vm.lineDetailShow ? 'hide' : '',
      _vm.noticeData.length <= 0 ? 'top-one' : 'top-two',
    ]}):_vm._e(),(_vm.sid != _vm.curSid && _vm.map_id != _vm.curMap_id)?_c('div',{class:[
      'back-main',
      _vm.detailViewShow || _vm.lineDetailShow ? 'hide' : '',
      _vm.noticeData.length <= 0 ? 'top-one' : 'top-two',
    ],on:{"click":_vm.backMain}},[_vm._v(" 县域地图 ")]):_vm._e(),(_vm.isLBS && _vm.map && _vm.mapInitEnd)?_c('location',{attrs:{"bottomMargin":_vm.bottomMargin}}):_vm._e(),(_vm.returnViewShow)?_c('div',{class:['return-scenic', _vm.bottomMargin ? 'iphonex-mb' : ''],on:{"click":_vm.returnCenter}},[_c('i',{staticClass:"icon"}),_vm._v(" 返回景区 ")]):_vm._e(),_c('div',{class:['bottom-nav', _vm.detailViewShow || _vm.lineDetailShow ? 'hide' : '']},[_c('div',{staticClass:"nav-item",on:{"click":_vm.toList}},[_c('div',{staticClass:"icon list"}),_c('p',{staticClass:"title"},[_vm._v("列表")])]),(_vm.scenicInfo.kefu_switch)?_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.openPage({ url: '/kefu' })}}},[_c('div',{staticClass:"icon customer"}),_c('p',{staticClass:"title"},[_vm._v("客服")])]):_vm._e(),_c('div',{staticClass:"nav-item",on:{"click":function($event){_vm.mapSetViewShow = true}}},[_c('div',{staticClass:"icon setting"}),_c('p',{staticClass:"title"},[_vm._v("设置")])]),(_vm.isYzsxh)?_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.jumpOtherMiniPro()}}},[_c('div',{staticClass:"icon ar"}),_c('p',{staticClass:"title"},[_vm._v("AR")])]):_vm._e()]),(_vm.scenicInfo.route_switch)?_c('div',{class:['recommend', _vm.detailViewShow || _vm.lineDetailShow ? 'hide' : ''],on:{"click":function($event){_vm.lineSelect();
      _vm.currentMarkersIndex = 0;}}},[_c('img',{staticClass:"recommend-image",attrs:{"src":require('@/assets/recommend-line.png')}})]):_vm._e(),(_vm.detailViewShow)?_c('detail-view',{ref:"detailview",attrs:{"isLineMarker":_vm.isLineMarker,"isAuth":_vm.is_auth,"markerData":_vm.prevTargetMarker.getExtData()}}):_vm._e(),(_vm.lineSelectShow)?_c('div',{staticClass:"line-select-view"},[_c('div',{staticClass:"bg",on:{"click":function($event){_vm.lineSelectShow = false}}}),_c('div',{staticClass:"main"},_vm._l((_vm.lineData),function(item,index){return _c('div',{key:index,class:[
          'line-item',
          _vm.bottomMargin && index == _vm.lineData.length - 1
            ? 'iphonex-pd-bottom'
            : '',
        ],on:{"click":function($event){return _vm.showLineDetail(index)}}},[_c('div',{staticClass:"title-box flex jb ac"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(`${index + 1}号线路`))]),_c('div',{staticClass:"title-icon"})]),_c('div',{staticClass:"info flex jb ac"},[_c('div',{staticClass:"flex ac"},[_c('div',{staticClass:"info-num"},[_vm._v(_vm._s(item.markers.length)+"个景点")]),_c('div',{staticClass:"info-icon"}),_c('div',{staticClass:"flex ac"},[_c('div',{staticClass:"info-distance"},[_vm._v(" "+_vm._s(item.distance < 1000 ? item.distance : (item.distance / 1000).toFixed(2))+" ")]),_c('div',{staticClass:"info-company"},[_vm._v(" "+_vm._s(item.distance < 1000 ? "m" : "Km")+" ")])])]),_c('div',{staticClass:"info-btn"},[_vm._v(" 查看详情 ")])])])}),0)]):_vm._e(),(_vm.lineDetailShow)?_c('line-view',{attrs:{"lineData":_vm.lineData,"index":_vm.selectLineIndex}}):_vm._e(),(_vm.mapSetViewShow)?_c('map-set',{attrs:{"toAsk":_vm.toAsk}}):_vm._e(),(_vm.langepop)?_c('LanuageSet'):_vm._e(),(_vm.setAutoPlay)?_c('auto-play'):_vm._e(),(_vm.guideFlag == 0)?_c('presentation',{attrs:{"guideFlag":_vm.guideFlag}}):_vm._e(),_c('div',{class:['footer-linear', _vm.detailViewShow || _vm.lineDetailShow ? 'hide' : '']})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-set-view"},[_c('div',{staticClass:"bg",on:{"click":function($event){_vm.parent.langepop = false}}}),_c('div',{staticClass:"main mains"},[_c('div',{staticClass:"main-title flex ac jc"},[_vm._v("语音选择")]),_c('div',{staticClass:"main-dec flex ac jc"},[_vm._v(" 小提示："+_vm._s(_vm.parent.lange == "girl" ? "御姐女神" : _vm.parent.lange == "boy" ? "帅气男神" : "英语（English）")+"为系统默认语音 ")]),_c('div',[_c('section',{class:['flex ac jb cell', _vm.parent.lange == 'girl' ? 'active' : ''],on:{"click":function($event){_vm.parent.lange = 'girl';
          _vm.parent.langepop = false;}}},[_c('section',{staticClass:"flex ac jc"},[_vm._v("女声")]),_c('section',{class:[_vm.parent.lange == 'girl' ? 'borders' : 'border']})]),_c('section',{class:['flex ac jb cell', _vm.parent.lange == 'boy' ? 'active' : ''],on:{"click":function($event){_vm.parent.lange = 'boy';
          _vm.parent.langepop = false;}}},[_c('section',{staticClass:"flex ac jc"},[_vm._v("男声")]),_c('section',{class:[_vm.parent.lange == 'boy' ? 'borders' : 'border']})]),_c('section',{class:[
          'flex ac jb  cell',
          _vm.parent.lange == 'English' ? 'active' : '',
        ],on:{"click":function($event){_vm.parent.lange = 'English';
          _vm.parent.langepop = false;}}},[_c('section',{staticClass:"flex ac jc"},[_vm._v("英语（English）")]),_c('section',{class:[_vm.parent.lange == 'English' ? 'borders' : 'border']})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "mint-ui/lib/style.css";
import Mint from "mint-ui";
import wx from "weixin-js-sdk";
import "./css/style.scss";
import "./css/common.scss";
import "./css/kefu.scss";
var util = require("@/utils/util");
Vue.use(Mint);
Vue.config.productionTip = false;
Vue.prototype.setDataArr = function(obj) {
  Object.assign(this.$data, obj);
};

//配置全局客服
Vue.prototype.kefu = "400-858-1616";

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.path == "/" && to.query.hasOwnProperty("scan")) {
    //扫码拦截重定向，防止刷新时记录无效统计
    qrcodeStatistics(to.query);
    let query = { ...to.query };
    delete query.scan;
    query.t = new Date().getTime();
    util.openPage({
      url: to.path,
      data: query,
    });
  } else {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
  }
});

//扫码统计
var qrcodeStatistics = function(query) {
  util.requests("post", {
    url: "statistics/scanCode",
    data: query,
  });
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

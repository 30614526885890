<template>
  <div class="home">
    <!-- 顶部导航 -->
    <div :class="['top-nav flex ac']">
      <div class="serch-btn" @click="openPages(map_id)"></div>
      <div class="select-marker flex">
        <div
          :class="['marker-item', index == currentMarkersIndex ? 'active' : '']"
          v-for="(item, index) in mapData"
          :key="index"
          @click="setMarkers(item.markers, index)"
        >
          {{ item.type }}
        </div>
      </div>
      <div class="more-btn-box flex jc">
        <div class="more-btn" @click="moreModelToogle"></div>
      </div>
    </div>
    <div class="top-nav-more" v-if="navMoreShow">
      <div class="bg" @click="moreModelToogle"></div>
      <div class="main">
        <div class="title">
          全部
          <span class="nav-more-close" @click="moreModelToogle"></span>
        </div>
        <div class="markers-box">
          <span
            :class="[
              'marker-item',
              index == currentMarkersIndex ? 'active' : '',
            ]"
            @click="setMarkers(item.markers, index)"
            v-for="(item, index) in mapData"
            :key="index"
            >{{ item.type }}</span
          >
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="list-box">
      <div class="list-item" v-for="item in currentMarkers" :key="item.id" @click="showDetail(item)">
        <img class="item-img" :src="item.img">
        <div class="item-info">
          <div class="item-title">{{item.name}}</div>
          <div class="item-distance" v-if="currentPosition && item.dis && item.dis.length>0"><span>{{item.dis[0]}}</span> {{item.dis[1]}}</div>
          <div :class="['item-msg', (currentPosition&&item.dis&&item.dis.length>0) ? 'line-one':'line-two']">{{item.info_text}}</div>
        </div>
      </div>
    </div>

    <!-- 景区详情 -->
    <detail-view
      ref="detailview"
      v-if="detailViewShow"
      :isLineMarker="false"
      :isAuth="true"
      :markerData="currentMarkerData"
    ></detail-view>
    <!--景区详情背景板-->
    <div class="detail-mask" v-show="detailViewShow" @click="detailViewShow=false"></div>
  </div>
</template>

<script>
import AMap from "AMap";
var util = require("@/utils/util");
import DetailView from "@/components/DetailView.vue";
import { Range, Toast, Indicator } from "mint-ui";
// 测试JSON数据

export default {
  name: "home",
  components: {
    DetailView,
    Range,
  },
  data() {
    return {
      map_id: 0,
      mapData: [], //地图数据点数据集合
      currentMarkers: [],//当前数据类型下的数据点列表
      currentMarkersIndex: 0, //当前点击的数据点
      navMoreShow: false, //顶部查看更多数据点模态框
      detailViewShow: false, //景点详情弹窗
      isLineMarker: false, //判断点击的图标是否属于路线内
      currentMarkerData: {}, //存储当前点击的marker，用于还原点击新图标时的样式控制。
      currentAudio: null, //当前播放的音频
      currentAudioState: false, //当前播放音频样式控制
      currentPosition: null, //当前定位经纬度
    };
  },
  mounted() {
    let map_id = this.$route.query.map_id;
    this.getScenicMapData(map_id);
    this.setDataArr({
      map_id: map_id
    })

    //定位信息
    let lat = this.$route.query.lat;
    let lng = this.$route.query.lng;
    if(lat && lng){
      this.setDataArr({
        currentPosition: [lng, lat]
      })
    }else{
      this.setLocation();
    }
  },
  watch: {
    currentPosition(){
      let that = this;
      let markersArr = that.currentMarkers;
      markersArr.forEach((item) => {
        if (!item.position[0] || !item.position[1]) {
          return true;
        }
        that.GetDistance(item.position, item);
      });
    },
    detailViewShow() {
      if (this.currentAudio) {
        this.currentAudioState = !this.currentAudio.paused;
      }
    },
  },
  methods: {
    // 获取页面数据点参数跳转
    getPageMarkerData() {
      var that = this;
      var spot_id = that.$route.query.spot_id;
      var point_id = that.$route.query.point_id;
      var line_id = that.$route.query.line_id;
      if (spot_id && point_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, index);
            item.markers.forEach((marker, mkIndex) => {
              if (marker.id == point_id) {
                that.map.setZoom(that.maxZoom);
                that.map.panTo(that.currentMarkers[mkIndex].position);
                that.markerEvent(that.currentMarkers[mkIndex], false);
              }
            });
          }
        });
      } else if (spot_id && that.mapData.length > 0) {
        that.sceniceModelShow = false;
        that.mapData.forEach((item, index) => {
          if (item.id == spot_id) {
            that.setMarkers(item.markers, index);
          }
        });
      }
      if (line_id && that.lineData.length > 0) {
        that.sceniceModelShow = false;
        that.lineData.forEach((item, index) => {
          if (item.id == line_id) {
            that.showLineDetail(index);
          }
        });
      }
    },
    
    // 设置数据点
    setMarkers(markersArr, index) {
      var that = this;
      that.currentMarkersIndex = index;
      that.currentMarkers = [];
      markersArr.forEach((item) => {
        if (!item.position[0] || !item.position[1]) {
          return true;
        }
        that.GetDistance(item.position, item);
        that.currentMarkers.push(item);
      });
      that.navMoreShow = false;
    },
    isIphone() {
      var isIphone = /iphone/gi.test(window.navigator.userAgent);
      var windowW = window.screen.width;
      var windowH = window.screen.height;
      var pixelRatio = window.devicePixelRatio;

      var isIPhoneX =
        isIphone &&
        pixelRatio &&
        pixelRatio === 3 &&
        windowW === 375 &&
        windowH === 812;
      var isIPhoneXSMax =
        isIphone &&
        pixelRatio &&
        pixelRatio === 3 &&
        windowW === 414 &&
        windowH === 896;
      var isIPhoneXR =
        isIphone &&
        pixelRatio &&
        pixelRatio === 2 &&
        windowW === 414 &&
        windowH === 896;

      if (isIPhoneX || isIPhoneXSMax || isIPhoneXR) {
        // 底部增加line-main-pb样式
        this.setDataArr({
          bottomMargin: true,
        });
      }
    },
    getScenicMapData(map_id) {
      Indicator.open("加载中...");
      let that = this;
      that.mapInitEnd = false;
      that.curMap_id = map_id;
      if (!map_id) {
        util.showToast("获取数据错误");
        return false;
      }
      util
        .requests("post", {
          url: "map_data/getData",
          data: { map_id: map_id },
        })
        .then((res) => {
          if (res.code) {
            that.setDataArr({
              mapData: res.data.spots,
            });
            //
            that.setMarkers(res.data.spots[0].markers, 0);
            document.title = res.data.scenic_info.name;
            // this.getPageMarkerData(); //加载页面参数数据点
            setTimeout(() => {
              Indicator.close();
            }, 500);
          } else {
            Indicator.close();
            util.showToast("获取数据异常");
          }
        });
    },
    // 显示更多模态框
    moreModelToogle() {
      this.navMoreShow = !this.navMoreShow;
    },
    //弹出详情
    showDetail(item){
      this.setDataArr({
        currentMarkerData: item,
        detailViewShow: true,
      })
    },
    GetDistance(itemPosition, row) {
      let that = this;
      if(that.currentPosition){
        let dis = AMap.GeometryUtil.distance(
            itemPosition,
            that.currentPosition
        );
        // row.dis = [
        //   dis<1000 ? dis: (dis/1000).toFixed(2),
        //   dis<1000 ? 'm':'Km'
        // ]
         row.dis = [
          dis<100 ? 0: (dis/1000).toFixed(1),
          'Km'
        ]
      }
    },
    getPointInfo(pid, isFirst, marker, isLine, item, icon, index) {
      let that = this;

      if (isFirst) {
        marker.setExtData({
          id: item.id,
          name: item.name,
          position: item.position,
          icon: isLine
            ? `<div class="amap-line-marker-icon">${index + 1}</div>`
            : that.divIcon(icon),
          audio: item.audioSrc,
          audio_boy: item.audioSrc,
          audio_girl: item.audioSrc_girl,
          audio_en: item.audioSrc_en,
          relation_toilet_id: item.relation_toilet_id,
          audio_duration: item.audio_duration,
          audio_en_duration: item.audio_en_duration,
          audio_girl_duration: item.audio_girl_duration,
          info: item.info,
          like: item.like,
          isLike: item.user_is_like,
          autoPlayAear: item.autoPlayAear,
          img: item.img ? item.img : defaultImg,
          is_charge: item.is_charge,
          arrIndex: index,
          relation_sid: item.relation_sid,
          relation_scenic_type: item.relation_scenic_type,
          relation_map_id: item.relation_map_id,
          vr_url: item.vr_url,
          wx_appid: item.wx_appid,
        });
        that.markerEvent(marker, isLine);
      }
      return false;
    },
    // 定位功能
    setLocation() {
      var that = this;
      var geolocation = new qq.maps.Geolocation();
      geolocation.getLocation(that.onComplete, that.onError);
      geolocation.watchPosition(that.onComplete);
    },
    //解析定位结果
    onComplete(data) {
      var that = this;
      if(data.lng && data.lat){
        that.currentPosition = [data.lng, data.lat];
      }
    },
    //解析定位错误信息
    onError(data) {
      return false;
    },
    openPages(e) {
      const ts = this;
      if (ts.currentPosition) {
        ts.$router.push({
          path: "/search",
          query: {
            mapid: e,
            lat: ts.currentPosition[1],
            lng: ts.currentPosition[0],
          },
        });
      } else {
        ts.$router.push({ path: "/search", query: { mapid: e } });
      }
    },
    openPage: util.openPage,
  },
};
</script>

<template>
  <div class="kefu-box">
    <main>
      <div class="module service" @click="openPage({ url: '/question' })">
        <div class="title">联系客服</div>
        <div class="sub-title">常见问题快速查找解决</div>
      </div>
      <!-- <div class="module complaint" @click="openPage({ url: '/complaint' })">
        <div class="title">在线投诉</div>
        <div class="sub-title">一键快速提交投诉单</div>
      </div> -->
    </main>
    <footer>
      <a class="phone-btn" :href="'tel:' + kefu">
        <i class="phone"></i>
        客服电话：{{ kefu }}
      </a>
    </footer>
  </div>
</template>

<script>
var util = require("@/utils/util");
export default {
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    openPage: util.openPage,
  },
};
</script>

import md5 from "js-md5"; //引入md5
import Qs from "qs"; //post data数据格式转换
import router from "@/router";
import request from "./request";
import upload from "./upload";
import { Toast } from "mint-ui";

import exoprtStorage from "../utils/modules/storage";
import exoprtArrayFun from "../utils/modules/ArrayFun";

//固定位数随机字符串,默认32位
export function randomString(len) {
  len = len || 32;
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  var maxPos = $chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
//对象键值排序
export function objKeySort(obj) {
  var newkey = Object.keys(obj).sort();
  var newObj = {};
  for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
}
//签名使用
export function makeSign(data) {
  data = objKeySort(data);
  if (data.hasOwnProperty("sign")) {
    delete data["sign"];
  }
  data = Qs.stringify(data);
  var appkey = "0hQU02kIX%NjE6iSivc2nnYG8g2OV";
  data = data + "&key=" + appkey;
  return md5(data).toLowerCase();
}

export const requests = function(method, e, that) {
  if (!method) {
    return;
  }
  if (!e.hasOwnProperty("url")) {
    return;
  }
  let obj = {};

  if (method == "post") {
    obj = {
      url: e.url,
      method: "post",
      data: e.data,
    };
  } else if (method == "get") {
    obj = {
      url: e.url,
      method: "get",
      params: e.data,
    };
  }
  if (e.headers) {
    obj.headers = e.headers;
  }

  return request(obj);
};

export const uploadRequest = function(method, e, that) {
  if (!method) {
    return;
  }
  if (!e.hasOwnProperty("url")) {
    return;
  }
  let obj = {};

  if (method == "post") {
    obj = {
      url: e.url,
      method: "post",
      data: e.data,
    };
  } else if (method == "get") {
    obj = {
      url: e.url,
      method: "get",
      params: e.data,
    };
  }
  if (e.headers) {
    obj.headers = e.headers;
  }

  return upload(obj);
};

/* //跳转vue */
export const openPage = function(e) {
  if (!e.hasOwnProperty("url")) {
    return;
  }
  var params = "";
  if (e.hasOwnProperty("data")) {
    //e.data={}类型
    params = {};
    for (let i in e.data) {
      if (
        typeof e.data[i] == "string" &&
        !e.data[i].replace(/(^\s*)|(\s*$)/g, "")
      ) {
        continue;
      } else {
        params[i] = e.data[i];
      }
    }
  }
  switch (e.hreftype) {
    case "redirectTo":
      return router.replace({
        path: e.url,
        query: params,
      });
      break;
    case "navigateBack":
      if (parseInt(e.url) >= 0) {
        return;
      }
      return router.go(e.url);
      break;
    default:
      //navigateTo
      return router.push({
        path: e.url,
        query: params,
      });
  }
};
/* //跳转小程序 */
var isJump = false;
export const openPageMini = function(e) {
  if (isJump) {
    return false;
  }
  if (!e.hasOwnProperty("url")) {
    return;
  }
  let url = e.url;
  switch (e.hreftype) {
    case "redirectTo":
      wx.miniProgram.redirectTo({ url: url });
      break;
    case "switchTab":
      wx.miniProgram.switchTab({ url: url });
      break;
    case "reLaunch":
      wx.miniProgram.reLaunch({ url: url });
      break;
    case "navigateBack":
      if (parseInt(e.url) >= 0) {
        return;
      }
      wx.miniProgram.navigateBack({ delta: e.url });
      break;
    default:
      //navigateTo
      wx.miniProgram.navigateTo({ url: e.url });
  }
  isJump = true;
  setTimeout(function() {
    isJump = false;
  }, 1500);
};
//去除字符串两端空格
export const trim = function(str) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
};

//删除操作和提示
export const doDel = function(e) {
  //e={url，data,successFun}
  if (!e.url) {
    return;
  }
  MessageBox.confirm("确认删除?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      requests("post", { url: e.url, data: e.data }).then(function(res) {
        e.successFun(res);
        message.success("删除成功");
      });
    })
    .catch(() => {});
};

//vue渲染经过转义的html字符串方法 html解码
export const htmlDecode = function(str) {
  let e = document.createElement("div");
  e.innerHTML = str;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

export const showToast = function(
  msg = "操作成功",
  iconClass = "",
  duration = 3000
) {
  Toast({
    message: msg,
    iconClass: iconClass,
    duration: duration,
  });
};

export const myStorage = exoprtStorage; //本地存储与本地会话存储操作
export const ArrayFun = exoprtArrayFun; //数组操作
